import requestBongkarIndex from "@/components/lincMobile/requestBongkar/requestBongkar.vue";
import requestBongkarDetail from "@/components/lincMobile/requestBongkar/requestBongkarDetail.vue";

let routesRequestBongkar = [
    {
      path: '',
      meta: {
        title: "Orders",
      },
      name: "RequestBongkarIndex",
      component: requestBongkarIndex
    },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "requestBongkarDetail",
      component: requestBongkarDetail
    },
   
]

export default routesRequestBongkar
  