<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component classMenu="salesProject"></sidebar-component>
      <div class="page-wrapper">
        <navbar-component></navbar-component>
        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Sales Project Kick Off
                  </h4>
                  <button
                    v-if="permision_role.method_upload"
                    :disabled="isLoading"
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>
                  <button
                    :disabled="isLoading"
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                  <!-- <button
                    v-if="permision_role.method_upload"
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>
                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button> -->
                </div>

                <div class="card-body">
                  <vue-good-table
                    mode="remote"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    @on-selected-rows-change="selectionChanged"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >
                    <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>
                    <div slot="selected-row-actions">
                      <button
                        class="btn btn-warning btn-xs"
                        style="margin-right: 20px"
                        @click="massEdit"
                      >
                        <i class="mdi mdi-pencil-box-multiple"></i>Mass Edit
                      </button>
                      <button
                        class="btn btn-success btn-xs"
                        style="margin-right: 20px"
                        @click="sendToBlujay"
                      >
                        <i class="mdi mdi-send-outline"></i>Send To Billable
                        Rate
                      </button>

                      <span> </span>
                    </div>

                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_delete"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'unit_price'">
                        {{ props.row.unit_price.toLocaleString() }}
                      </span>
                      <span v-else-if="props.column.field == 'loading'">
                        {{ props.row.loading.toLocaleString() }}
                      </span>
                      <span v-else-if="props.column.field == 'unloading'">
                        {{ props.row.unloading.toLocaleString() }}
                      </span>
                      <span v-else-if="props.column.field == 'multidrop'">
                        {{ props.row.multidrop.toLocaleString() }}
                      </span>
                      <span v-else-if="props.column.field == 'overnight'">
                        {{ props.row.overnight.toLocaleString() }}
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
                <div class="card-footer">
                  <button
                    :disabled="isLoading"
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>
                </div>
              </div>
            </div>
            <modal-sales-project-edit
              :showModal="showModalSalesProjectEdit"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-sales-project-edit>
          </div>
        </div>
        <!--end::content-->
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalSalesProjectEdit from "@/components/modal/modalSalesProjectMassEdit";
export default {
  name: "SalesProject",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-sales-project-edit": modalSalesProjectEdit,
    //multiselect: multiselect,

    // DirectionsRenderer,
  },
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      passData: "",
      isLoading: false,
      errors: [],
      permision_role: [],
      rowsSelection: [],
      salesProjectIds: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      showModalSalesProjectEdit: false,
      totalRecords: 0,
      header: null,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "updated_at",
          type: "desc",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "OPTY ID",
          field: "opty_id",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By OPTY ID", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status Rate",
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status Rate", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Unit",
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By SAP Code", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Origin C",
          field: "origin",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Origin", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Origin K",
          field: "origin_k",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Origin", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Destination C",
          field: "destination",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Destination K",
          field: "destination_k",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Destination", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Qty",
          field: "qty",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Qty", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Unit Price",
          field: "unit_price",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Unit Price", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UOM 1",
          field: "uom1",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By UOM 1", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UOM 2",
          field: "uom2",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By UOM 2", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Loading",
          field: "loading",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Loading", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Unloading",
          field: "unloading",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Unloading", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Multidrop",
          field: "multidrop",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Multidrop", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Overnight",
          field: "overnight",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Overnight", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Lead Time",
          field: "leadtime",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Lead Timeß", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Effective Date",
          field: "effective_date",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Effective Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Expired Date",
          field: "expired_date",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Expired Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  methods: {
    downloadData() {},
    backForm() {
      window.location.href = "/sales-project";
    },
    deleteData(index,row){
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
       
          const baseURI =
            this.$settings.endPoint + "sales-project/delete-detail/" + row.sales_project_detail_id ;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },
    sendToBlujay() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            sales_project_details: this.salesProjectIds,
          };
          const baseURI =
            this.$settings.endPoint + "sales-project/send-billable-rate";
          this.$http
            .post(baseURI, data)
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push("/server-error");
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
              this.loadItems();
            });
        }
      });
    },
    massEdit() {
      this.passData = {
        ids: this.salesProjectIds,
        header: this.header,
      };
      this.showModalSalesProjectEdit = true;
    },
    closeMyModal() {
      this.showModalSalesProjectEdit = false;
      this.loadItems();
    },
    editData(index, row) {
      var params = this.$onRandom(row.sales_project_detail_id);
      window.location.href =
        "/sales-project/detail/" +
        this.$route.params.id +
        "/won-rate/" +
        params;
      // this.passData = row.sales_project_id;
      // this.showModalSalesProjectEdit = true;
    },
    // load items is what brings back the rows from server
    loadItems() {
      var params = this.$onBehind(this.$route.params.id);
      const baseURI =
        this.$settings.endPoint + "sales-project/detail/" + params+`?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&opty_id=${this.serverParams.columnFilters.opty_id}&type_truck=${this.serverParams.columnFilters.type_truck}&origin=${this.serverParams.columnFilters.origin}&destination=${this.serverParams.columnFilters.destination}&uom1=${this.serverParams.columnFilters.uom1}&description=${this.serverParams.columnFilters.description}&origin_k=${this.serverParams.columnFilters.origin_k}&destination_k=${this.serverParams.columnFilters.destination_k}`;
    
      
      return this.$http.get(baseURI).then((response) => {
        this.rows = response.data.datas.detail;
        this.header = response.data.datas;
        delete this.header.detail;
        // this.totalRecords = response.data.datas.total;
        this.totalRecords = 0;
      });
    },
    selectionChanged(ev) {
      this.rowsSelection = [];

      this.rowsSelection = ev.selectedRows;
      this.salesProjectIds = [];
      this.rowsSelection.forEach((item) => {
        this.salesProjectIds.push(item.sales_project_detail_id);
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },
    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "SalesProjectController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },
  },
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>

<style></style>
