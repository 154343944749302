<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Master Ujo {{ $route.query.params }}
                  </h4>
                 
                  <button
                    v-if="permision_role.method_create && $route.query.params!='approval'"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  
               
              

                    <span v-if="!isLoadingRejectAll">
                      <button
                        v-if="permision_role.method_create && $route.query.params=='approval' && typeApproval=='KALKULASI' || permision_role.method_create && $route.query.params=='approval' && typeApproval=='TANGGAL'"
                        class="btn btn-danger float-end btn-xs"
                        style="margin-right: 5px"
                        @click.prevent="approveUjo()"
                      >
                        <i class="mdi mdi-check" ></i>
                        Approve All
                      </button>
                    </span>
                   

                 
                 


                 

                  <span v-if="!isLoadingRejectAll">


                    <button
                      v-if="permision_role.method_create && $route.query.params=='approval' && typeApproval=='KALKULASI' || permision_role.method_create && $route.query.params=='approval' && typeApproval=='TANGGAL'"
                      class="btn btn-primary float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="rejectUjo()"
                    >
                      <i class="mdi mdi-close" ></i>
                      Reject All
                    </button>

                  </span>

          

                    <span v-if="isLoadingRejectAll" class="d-flex align-items-center">
                      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                      <strong>Loading...</strong>
                    </span>



                  

                  <!-- <button
                    v-if="permision_role.method_create && $route.query.params=='approval'"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="approveUjo()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    Approve Later
                  </button> -->

                  <button
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>

                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-user"
                    mode="remote"
                    :select-options="{
                      enabled: enabledSelect,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }" 
                    @on-selected-rows-change="selectionChanged"

                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="$route.query.params === 'request' ? columnsRequest : $route.query.params === 'approval' ? columnsApprove : columns"
                  >

                  <div slot="table-actions"  v-if="permision_role.method_create && $route.query.params!='approval' && $route.query.params!='request'">
                    {{ $t("no_select_data") }}
                  </div>
                  <div slot="selected-row-actions"  v-if="permision_role.method_create && $route.query.params!='approval' && $route.query.params!='request'">
                    <button
                      class="btn btn-danger float-end btn-xs"
                      style="margin-right: 5px"
                      @click.prevent="updateAllDate()"
                    >
                      <i class="link-icon" data-feather="edit"></i>
                      {{ $t("updateDate") }}
                    </button>

                  </div>

                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_update && $route.query.params!='approval'"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                          v-if="permision_role.method_delete  && $route.query.params!='approval'"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                          v-if="(permision_role.method_read && $route.query.params=='approval' && props.row.type_approval==='EDIT') || (permision_role.method_read && $route.query.params=='approval' && props.row.type_approval==='NEW UJO')"
                          title="Approve"
                          @click.prevent="approveSatuan(props.row.rm_ujo_master_id,props.row.type_approval)"
                        >
                          <i
                            class="mdi mdi-check"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                            v-if="(permision_role.method_read && $route.query.params=='approval' && props.row.type_approval==='EDIT') || (permision_role.method_read && $route.query.params=='approval' && props.row.type_approval==='NEW UJO')"
                          title="Reject"
                          @click.prevent="rejectSatuanUjo(props.row.rm_ujo_master_id,props.row.type_approval)"
                        >
                          <i
                            class="mdi mdi-close"
                            style="font-size: 16px"
                          ></i>
                        </a>

                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>

            <modal-aprrove-reason
              :showModal="showModal"
              :data="modalData"
              :url="url"
              @closeModal="closeMyModal"
            ></modal-aprrove-reason>

            <modal-reject-reason
              :showModal="showModalReject"
              :data="modalData"
              :url="urlrejected"
              @closeModal="closeMyModal"
            ></modal-reject-reason>


            <modal-update-all-date
              :showModal="showModalUpdateAllDate"
              :data="modalData"
              @closeModal="closeMyModal"
            ></modal-update-all-date>


            <modal-approve-ujo
              :showModal="showModalApprove"
              :data="modalDataUjo"
              :url="url"
              @closeModal="closeMyModal"
            ></modal-approve-ujo>


           
            
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalApproveWithReason from "@/components/modal/modalApproveWithReason";
import modalRejectWithReason from "@/components/modal/modalRejectWithReason";
import modalUpdateAllDate from "@/components/modal/modalUpdateAllDate";
import modalApproveUjo from "@/components/modal/modalApproveUjo"; 

export default {
  name: "BranchIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-aprrove-reason":modalApproveWithReason,
    "modal-reject-reason":modalRejectWithReason,
    "modal-update-all-date":modalUpdateAllDate,
    "modal-approve-ujo":modalApproveUjo
  },
  data() {
    return {
      url:"rm-ujo-master/approve",
      urlrejected:"rm-ujo-master/reject",
      showModal: false,
      enabledSelect:false,
      showModalRateBreak:false,
      showModalReject:false,
      showModalUpdateAllDate:false,
      showModalApprove:false,
      params:"",
      isLoadingRejectAll:false,
      isLoadingApproveAll:false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      typeApproval:'',
      totalRecords: 0,
      rowsSelection: [],
      modalData:{
        id:null
      },
      modalDataUjo:{
        rm_ujo_master_id:null,
        type_approval:null
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columnsRequest: [
      {
          label: 'Status',
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on entertype_kalkulasi
          },
      },
      {
          label: "Date Reject",
          field: "tanggal_reject",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reason Reject",
          field: "reason_reject",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("type_truck"),
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("type_truck"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Master Ujo Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // {
        //   label: this.$t("branch_province_txt"),
        //   field: "branch_name",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("branch_province_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        // {
        //   label: this.$t("routeTxt"),
        //   field: "concatRoute",
        //   filterOptions: {
        //     enabled: false, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("routeTxt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        {
          label: this.$t("distance") + " ( KM )",
          field: "distance",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("distance"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( L )",
          field: "fuel_liter",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( Rp )",
          field: "fuel_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tol ( Rp )",
          field: "toll_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tol", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("CrossTxt"),
          field: "ferry",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("CrossTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Total Ujo",
          field: "ujo_total",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("totalRoundingTxt"),
          field: "ujo_total_rounding",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        // {
        //   label: this.$t("CrossTxt"),
        //   field: "cross",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("CrossTxt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        // {
        //   label: "Total Ujo",
        //   field: "ujo_total",
        //   formatFn:this.formatCurrency,
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By Total Ujo", // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        // {
        //   label: this.$t("totalRoundingTxt"),
        //   field: "ujo_total_rounding",
        //   formatFn:this.formatCurrency,
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By Total Ujo", // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      columns: [
      {
          label: 'Status',
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        
      {
        
        label: 'Kalkulasi',
        field: "type_kalkulasi",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },



      {
        
        label: 'Effective From',
        field: "effective_from",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },


      {
        
        label: 'Effective To',
        field: "effective_to",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },


      {
        
        label: 'Type',
        field: "type_ujo",
        filterOptions: {
          enabled: true, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: ['A','B'], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },




        {
          label: this.$t("type_truck"),
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("type_truck"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          }
        },
        {
          label: "Master Ujo Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // {
        //   label: this.$t("branch_province_txt"),
        //   field: "branch_name",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("branch_province_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        // {
        //   label: this.$t("routeTxt"),
        //   field: "concatRoute",
        //   filterOptions: {
        //     enabled: false, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("routeTxt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        {
          label: this.$t("distance") + " ( KM )",
          field: "distance",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("distance"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( L )",
          field: "fuel_liter",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( Rp )",
          field: "fuel_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tol ( Rp )",
          field: "toll_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tol", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("CrossTxt"),
          field: "ferry",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("CrossTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Total Ujo",
          field: "ujo_total",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("totalRoundingTxt"),
          field: "ujo_total_rounding",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],


      columnsApprove: [
      {
          label: 'Status',
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
        
          label: 'Schedule',
          field: "sent_schedule",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          }
        },

        {
        
          label: 'Type Approval',
          field: "type_approval",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Order Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["NEW UJO","EDIT","KALKULASI","TANGGAL"], 
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

      {
        
        label: 'Kalkulasi',
        field: "type_kalkulasi",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },



      {
        
        label: 'Effective From',
        field: "effective_from",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },


      {
        
        label: 'Effective To',
        field: "effective_to",
        filterOptions: {
          enabled: false, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: [], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },


      {
        
        label: 'Type',
        field: "type_ujo",
        filterOptions: {
          enabled: true, // enable filter for this column
          placeholder: "Filter By ", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: ['A','B'], // dropdown (with selected values) instead of text input
          trigger: "enter", //only trigger on enter not on keyup
        }
      },

        {
          label: this.$t("type_truck"),
          field: "type_truck",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("type_truck"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          }
        },
        {
          label: "Master Ujo Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // {
        //   label: this.$t("branch_province_txt"),
        //   field: "branch_name",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("branch_province_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        // {
        //   label: this.$t("routeTxt"),
        //   field: "concatRoute",
        //   filterOptions: {
        //     enabled: false, // enable filter for this column
        //     placeholder: "Filter By " + this.$t("routeTxt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        {
          label: this.$t("distance") + " ( KM )",
          field: "distance",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("distance"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( L )",
          field: "fuel_liter",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Solar ( Rp )",
          field: "fuel_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Solar", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tol ( Rp )",
          field: "toll_price",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tol", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("CrossTxt"),
          field: "ferry",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("CrossTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Total Ujo",
          field: "ujo_total",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t("totalRoundingTxt"),
          field: "ujo_total_rounding",
          formatFn:this.formatCurrency,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Total Ujo", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      rm_ujo_master_ids:[]
    };
  },
  watch: {},
  computed: {},
  methods: {
    formatCurrency(ev){
      return parseInt(ev).toLocaleString()
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmUjoMasterController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    closeMyModal(){
      this.showModal=false;
      this.showModalReject=false;
      this.showModalUpdateAllDate=false;
      this.showModalApprove=false;
      this.loadItems();
    },

    updateAllDate(){
      this.showModalUpdateAllDate=true;
      console.log(this.rm_ujo_master_ids);
      this.modalData={
        rm_ujo_master_ids:this.rm_ujo_master_ids
      }
    },

    approveUjo(){
      
      if(this.typeApproval==''){
        this.error("TIDAK BISA DILAKUKAN APPROVAL KARENA BELUM FILTER TYPE ARCHIVE")
      }else{
        this.modalDataUjo={
          rm_ujo_master_id:null,
          type_approve:this.typeApproval
        }
        this.showModalApprove=true;
      }
    },


    approveSatuan(rm_ujo_master_id,type_approve){
      
      this.modalDataUjo={
          rm_ujo_master_id:rm_ujo_master_id,
          type_approve:type_approve
        }
      this.showModalApprove=true;
    },


    rejectSatuanUjo(rm_ujo_master_id,type_approve) {

      this.$swal({
        title: this.$t("areYouSure"),
        text: 'Perubahan rate akan di reject',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        
        if (result.value) {
          
          const baseURI =
            this.$settings.endPoint + "rm-ujo-master/reject-all";
          this.$http
            .post(baseURI, {
            "function": 'reject-all',
            "rm_ujo_master_id":rm_ujo_master_id,
            "type_approval": type_approve,
          })
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.isLoadingRejectAll=false;
                this.success('Success');
                this.loadItems();
              } else {
                this.isLoadingRejectAll=false;
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
              
            })
            .catch((error) => {
              this.loading();
              this.isLoadingRejectAll=false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


    selectionChanged(ev) {
      this.rowsSelection = [];
     
      this.rowsSelection = ev.selectedRows;
   
      this.rm_ujo_master_ids=[];

      this.rowsSelection.forEach((item) => {
        this.rm_ujo_master_ids.push(item.rm_ujo_master_id);
     
      });
    },


    approveNow(){
     
      if(this.typeApproval==''){
        this.error("TIDAK BISA DILAKUKAN APPROVAL KARENA BELUM FILTER TYPE ARCHIVE ")
      }
    },

    approveLater(){

      if(this.typeApproval==''){
        this.error("TIDAK BISA DILAKUKAN APPROVAL KARENA BELUM FILTER TYPE ARCHIVE")
      }
    },


    downloadData() {
      this.fade(true);
      this.params=this.$route.query.params;
      var baseURI = this.$settings.endPoint + "rm-ujo-master";
      if(this.params=='request'){
        baseURI=this.$settings.endPoint + "rm-ujo-master/request";
      }else if(this.params=='approval'){
        baseURI=this.$settings.endPoint + "rm-ujo-master/approval";
      }
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&type_truck=${this.serverParams.columnFilters.type_truck}&name=${this.serverParams.columnFilters.name}&distance=${this.serverParams.columnFilters.distance}&fuel_liter=${this.serverParams.columnFilters.fuel_liter}&fuel_price=${this.serverParams.columnFilters.fuel_price}&toll_price=${this.serverParams.columnFilters.toll_price}&ujo_total=${this.serverParams.columnFilters.ujo_total}&ujo_total_rounding=${this.serverParams.columnFilters.ujo_total_rounding}&type_approval=${this.serverParams.columnFilters.type_approval}&type_ujo=${this.serverParams.columnFilters.type_ujo}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        }).catch((error) => {
          if (error.response) {
             if (error.response.status === 422) {
               this.errors = { message: ["File Not Found"] };
               this.error("File Not Found");
             } else if (error.response.status === 500) {
               this.$router.push("/server-error");
             } else {
               this.$router.push("/page-not-found");
             }
           }
         });
      },

    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var company_code = columnFilters["company_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     company_code: company_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },
    createData() {
      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "/master-ujo/add?params=request";
      }else if(this.params=='approval'){
        url = "/master-ujo/add?params=approval";
      }else{
        url="/master-ujo/add"
      }
    
      window.location.href = url;
     
    },

    showApprove(id){
      this.showModal=true;
      this.modalData={
        id:id
      }
    },


    showAReject(id){
      this.showModalReject=true;
      this.modalData={
        id:id
      }
    },

    uploadData() {
      window.location.href = "/master-ujo/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.rm_ujo_master_id);

      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "?params=request";
      }else if(this.params=='approval'){
        url = "?params=approval";
      }
  

      window.location.href = "/master-ujo/edit/" + params+url;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.rm_ujo_master_id);

      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "?params=request";
      }else if(this.params=='approval'){
        url = "?params=approval";
      }
  

      window.location.href = "/master-ujo/detail/" + params+url;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-ujo-master/delete/" + row.rm_ujo_master_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },



    rejectUjo() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: 'Perubahan rate akan di reject',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        this.isLoadingRejectAll=true;
        if (result.value) {
          
          const baseURI =
            this.$settings.endPoint + "rm-ujo-master/reject-all";
          this.$http
            .post(baseURI, {
            "function": 'reject-all',
            "type_approval": this.typeApproval,
          })
            .then((response) => {
              
              if (response.data.status === 200) {
                this.isLoadingRejectAll=false;
                this.success('Success');
                this.loadItems();
              } else {
                this.isLoadingRejectAll=false;
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
              
            })
            .catch((error) => {
              this.isLoadingRejectAll=false;
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }else{
          this.isLoadingRejectAll=false;
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.params=this.$route.query.params;
      let type_approval='';
      let type_ujo='';
      
      var baseURI = this.$settings.endPoint + "rm-ujo-master";
      if(this.params=='request'){
        type_approval='';
        type_ujo='';
        baseURI=this.$settings.endPoint + "rm-ujo-master/request";
      }else if(this.params=='approval'){
        type_ujo=this.serverParams.columnFilters.type_ujo;
        type_approval=this.serverParams.columnFilters.type_approval;
        baseURI=this.$settings.endPoint + "rm-ujo-master/approval";
      }
      

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;


      this.typeApproval=this.serverParams.columnFilters.type_approval;

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&status=${this.serverParams.columnFilters.status}&type_truck=${this.serverParams.columnFilters.type_truck}&name=${this.serverParams.columnFilters.name}&distance=${this.serverParams.columnFilters.distance}&fuel_liter=${this.serverParams.columnFilters.fuel_liter}&fuel_price=${this.serverParams.columnFilters.fuel_price}&toll_price=${this.serverParams.columnFilters.toll_price}&type_approval=${type_approval}&type_ujo=${type_ujo}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
   
    
    this.fetchIt();
    this.loading();

    if(this.$route.query.params!='approval' && this.$route.query.params!='request'){
      this.enabledSelect=true;
    }

    
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}
</style>
