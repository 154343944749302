import lincMobileIndex from "@/components/lincMobile/Index.vue";
import routesRequestBongkar from "@/components/lincMobile/requestBongkar/routes";
import routesRequestMuat from "@/components/lincMobile/requestMuat/routes";
import routesRequestLeadtime from "@/components/lincMobile/requestLeadtime/routes";
import routesRequestPod from "@/components/lincMobile/requestPod/routes";
import routesRequestBa from "@/components/lincMobile/requestBa/routes";
import routeRequestLeave from "@/components/lincMobile/requestLeave/routes";


export default [
    {
      path: "/request-muat",
      meta: {
        title: "Chain System",
      },
      children: routesRequestMuat,
      component: lincMobileIndex,
    },
    {
      path: "/request-bongkar",
      meta: {
        title: "Chain System",
      },
      children: routesRequestBongkar,
      component: lincMobileIndex,
    },
    {
      path: "/request-leadtime",
      meta: {
        title: "Chain System",
      },
      children: routesRequestLeadtime,
      component: lincMobileIndex,
    },
    {
      path: "/request-pod",
      meta: {
        title: "Chain System",
      },
      children: routesRequestPod,
      component: lincMobileIndex,
    },
    {
      path: "/request-ba",
      meta: {
        title: "Chain System",
      },
      children: routesRequestBa,
      component: lincMobileIndex,
    },
    {
      path: "/request-leave",
      meta: {
        title: "Chain System",
      },
      children: routeRequestLeave,
      component: lincMobileIndex,
    },
];