import { render, staticRenderFns } from "./podForm.vue?vue&type=template&id=bff4b89c&scoped=true"
import script from "./podForm.vue?vue&type=script&lang=js"
export * from "./podForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff4b89c",
  null
  
)

export default component.exports