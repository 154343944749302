<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Form Approve</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>


        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
            <div class="row mb-3 justify-content-center align-items-center">
              <div class="col text-center">
                <h1 style="color:red;">Warning</h1>
                <p>Perubahan Rate Akan dijalankan</p>
              </div>
            </div>


            <!--end::loading-data-->

            <div class="row mb-3 justify-content-center align-items-center">

              <div class="col-lg-8 d-flex justify-content-between">
                <!-- <div class="form-check" v-if="hiddenDownload" >
                  <input class="form-check-input" type="radio" id="radioA" v-model="selectApprove" value="EXCEL"
                    @change="handleChange">
                  <label class="form-check-label" for="radioA">Download Excel</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioA" v-model="selectApprove" value="NOW"
                    @change="handleChange">
                  <label class="form-check-label" for="radioA">Send To Blujay NOW</label>
                </div> -->
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioB" v-model="selectApprove" value="LATER"
                    @change="handleChange">
                  <label class="form-check-label" for="radioB">Send To Blujay LATER</label>
                </div>
              </div>
            </div>

            <div class="mb-3" v-if="hiddenDate">
              <!-- <label class="form-label">Effective To</label> -->
              <date-range-picker style="width: 100%" control-container-class="form-control" :locale-data="locale"
                v-model="tanggal" opens="center" :singleDatePicker="true" :timePicker24Hour="false" :timePicker="false"
                :showWeekNumbers="false" :minDate="minDate" >
              </date-range-picker>
              <!-- <textarea class="form-control" required v-model="forms.reason_approval" height="200px"></textarea> -->
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              No
            </button>

            <div v-if="isLoading" class="d-flex align-items-center">
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              <strong>Loading...</strong>
            </div>

            <button v-if="!isLoading" class="btn btn-primary" type="submit">Yes </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {

  components: {
    DateRangePicker
    // DateRangePicker
  },
  name: "ModalFormApprove",
  emits: ["closeModal"],

  props: {

    showModal: Boolean,
    data: null,
    url: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.minDate = tomorrow; // Hari ini
        // this.maxDate = tomorrow; // Besok
       
          this.tanggal.startDate=tomorrow;
          this.tanggal.endDate=tomorrow;
        if (this.data != '') {

          
          if(this.data?.rm_ujo_master_id){
            this.hiddenDownload=false;
          }
          // const startDate = this.data.delivery_date;
          // const endDate = new Date();

          // const startDate2 = this.data.return_date;
          // const endDate2 = new Date();
          console.log(this.data);
          this.forms = {
            type_approve: this.data.type_approve,
          };



        } else {

         
          this.forms = {
            type_approve: '',
            
          };


        }

        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date();

    return {
      selectApprove: "",
      hiddenDownload:true,
      tanggal: {
        startDate,
        endDate,
      },

      minDate: null,
      maxDate: null,

      isLoading: false,
      active: this.showModal,
      forms: [],
      locale: {
        format: "dd mmm yyyy",
      },
      return_date: [],
      delivery_date: [],
      equipment_name: [],
      fetchEquipmentName: [],
      hiddenDate: false,
    };
  },
  methods: {
    handleChange() {
      if (this.selectApprove == 'LATER') {
        this.hiddenDate = true;
      } else {
        this.hiddenDate = false;
      }
    },
    resetForm() {
      this.forms = [];
    },


    isInvalidDate(date) {
      // Ambil hari ini
      console.log(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Tentukan H + 1
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      // Nonaktifkan tanggal setelah H + 1
      return new Date(date) > tomorrow;
    },



    downloadExcel(excel){
      this.isLoading=true;
        const baseURI = `${this.$settings.endPoint}rm-ujo-master/approve-all-next-step`;
        var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
        var file_name = "download_" + CurrentDate + ".xlsx";

        return this.$http
          .post(
            baseURI,{
                  "excel" : excel
                },
            {
              responseType: "blob",
              timeout:3600000
            }
          )
          .then((response) => {
            this.isLoading = false;

            // Membuat URL untuk file yang akan diunduh
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', file_name);
            document.body.appendChild(fileLink);
            fileLink.click();

            // Emit event untuk menutup modal
            this.$emit("closeModal");
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
    },

    
    submitData() {
      if (this.selectApprove == '') {
        this.loadError('Please select approve Later or Now');
      }else if(this.selectApprove == 'EXCEL'){
        this.isLoading=true;
        const baseURI = `${this.$settings.endPoint}rm-ujo-master/approve-all`;

      return this.$http
        .post(
          baseURI,{
                "function": 'approve-all',
                "type_approval": this.forms.type_approve,
                "excel" : "yes"
              },
          {
            timeout:3600000
          }
        )
        .then((response) => {
          this.isLoading = false;

          if (response.data.status === 200) {
            this.$emit("closeModal");
            //this.downloadExcel('yes');
            this.loadSuccess('Success Approve');
           
          } else {
            this.loadError(response.data.messages);
          }
          // Emit event untuk menutup modal
          
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
      } else {
        this.$swal({
          title: this.$t('areYouSure'),
          text: "Perubahan Rate akan di jalankan ke blujay sekarang?",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            var functionApprove = "";
            var dataSubmit = {};
            console.log(this.forms);
            if (this.selectApprove === 'NOW') {
              functionApprove = "approve-all";
              dataSubmit = {
                "function": functionApprove,
                "type_approval": this.forms.type_approve,
                "rm_ujo_master_id":this.data.rm_ujo_master_id
              };
            } else {
              var send_schedule = moment(String(this.tanggal.startDate)).format("YYYY-MM-DD");
              functionApprove = "approve-later";
              dataSubmit = {
                "function": functionApprove,
                "type_approval": this.forms.type_approve,
                "send_schedule": send_schedule,
                "rm_ujo_master_id":this.data.rm_ujo_master_id
              };
            }
           
            const baseURI = `${this.$settings.endPoint}rm-ujo-master/`+functionApprove;

            this.$http.post(baseURI, dataSubmit,{timeout:3600000})
              .then((response) => {
                this.isLoading = false;
                if (response.data.status === 200) {
                  this.$emit("closeModal");
                  // if (this.selectApprove === 'NOW'){
                    
                  //   this.downloadExcel('yes');
                  // }else{
                  //   this.downloadExcel('no');
                  // }
                 
                  this.loadSuccess('Success Approve');
                 
                } else {
                  this.loadError(response.data.messages);
                }
              }).catch(error => {
                this.isLoading = false;
                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else {
                    // this.$router.push('/page-not-found');
                  }
                }
              });
          }
        });
      }
    },



    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      })
    },



    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      })
    },


  },
  created: function () {
    //this.loadDriver();

  },
  mounted() {
    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>