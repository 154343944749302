<template>
  <div>
    <div class="page-content">
      <div
        class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
      >
        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
          <div class="card">
            <div class="card-header">
              <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
              &nbsp;&nbsp;&nbsp;
              <h6
                class="card-title mb-0 float-start"
                style="padding-left: 25px"
              >
               FPPK {{ $t("fppkMenuPengajuan") }}
              </h6>
              <button
                class="btn btn-default btn-sm float-end"
                @click="resetForm"
                type="button"
              >
                <i class="link-icon" data-feather="repeat"></i>
                {{ $t("resetFormTxt") }}
              </button>
            </div>
            <div class="card-body">
              <!--begin::loading-data-->
              <div v-if="isLoading" class="d-flex align-items-center">
                <div
                  class="spinner-border ms-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong>{{ $t("loadingTxt") }}...</strong>
              </div>
              <!--end::loading-data-->

              <form
                class="forms-sample"
                @submit.prevent="submitData"
                method="POST"
              >
                <div
                  class="d-flex justify-content-between align-items-baseline mb-2"
                ></div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label"
                        >No Fppk </label
                      >
                      <div class="col-sm-7">
                        <input
                          type="text"
                          class="form-control"
                          id="period_code"
                          placeholder="No Fppk"
                          readonly
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row mb-3">
                      <label for="price" class="col-form-label col-sm-4"
                        >Status</label
                      >
                      <div class="col-sm-7">
                        <v-select v-model="forms.status" :options="fetchStatus">
                        </v-select>
                        <div v-if="errors.status">
                          <div
                            v-for="error in errors.status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="row mb-3">
                      <label for="price" class="col-form-label col-sm-4"
                        >Lokasi Unit</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.lokasi_unit" class="form-control">
                        <div v-if="errors.status">
                          <div
                            v-for="error in errors.status"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Tanggal FPPK</label
                      >
                      <div class="col-sm-7">
                        <date-range-picker
                          disabled
                          style="width: 100%"
                          :locale-data="locale"
                          v-model="fppkDate"
                          :opens="opens"
                          :singleDatePicker="true"
                          :timePicker24Hour="true"
                          :timePicker="true"
                        >
                        </date-range-picker>
                        <div v-if="errors.tanggal_fppk">
                          <div
                            v-for="error in errors.tanggal_fppk"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Nopol</label
                      >
                      <div class="col-sm-7">
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="nopol"
                          placeholder="No Fppk"
                          v-model="forms.nopol"
                        /> -->

                        <multiselect
                          v-model="truck"
                          :options="fetchTruck"
                          :multiple="false"
                          group-values="truck"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search "
                          track-by="vehicle_no"
                          @search-change="asyncSearchTruck"
                          label="vehicle_no"
                          
                          @input="changeNopol"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >



                        <div v-if="errors.nopol">
                          <div
                            v-for="error in errors.nopol"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Merk</label
                      >
                      <div class="col-sm-7">
                        <!-- <v-select
                          v-model="forms.merk"
                          :options="fetchMerk"
                          return-object
                        >
                        </v-select> -->

                         <input
                          type="text"
                          class="form-control"
                          id="merek"
                          readonly
                          placeholder=""
                          v-model="forms.merk"
                        />

                        <div v-if="errors.merk">
                          <div
                            v-for="error in errors.merk"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Jenis Unit</label
                      >
                      <div class="col-sm-7">
                        <input
                          type="text"
                          class="form-control"
                          id="merek"
                          readonly
                          placeholder=""
                          v-model="forms.jenis_unit"
                        />
                        <div v-if="errors.jenis_unit">
                          <div
                            v-for="error in errors.jenis_unit"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Driver NMK</label
                      >
                      <div class="col-sm-7">
                        <multiselect
                        v-model="nmk_driver"
                        :options="fetchDriver"
                        :multiple="false"
                        :close-on-select="true"
                        :custom-label="customLabel"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder="Driver NMK"
                        label="driver_id"
                        track-by="driver_id"
                        @input="changeDriver"
                        @search-change="onSearchChangeDriver"
                      > </multiselect>
                        <div v-if="errors.nmk_driver">
                          <div
                            v-for="error in errors.nmk_driver"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Driver Name</label
                      >
                      <div class="col-sm-7">
                        <input type="text" readonly v-model="forms.driverName" class="form-control">
                        <div v-if="errors.driverName">
                          <div
                            v-for="error in errors.driverName"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Hp Driver</label
                      >
                      <div class="col-sm-7">
                        <input type="text" readonly  v-model="forms.hp_driver" class="form-control">
                        <div v-if="errors.hp_driver">
                          <div
                            v-for="error in errors.hp_driver"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-form-label col-sm-4"
                        >Email Driver</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.email_driver" class="form-control">
                        <div v-if="errors.email_driver">
                          <div
                            v-for="error in errors.email_driver"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label"
                        >KM GPS</label
                      >
                      <div class="col-sm-5">
                        <input
                          type="number"
                          class="form-control"
                          id="period_code"
                          placeholder="KM GPS"
                          v-model="km_gps"
                          disabled
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <button type="button" v-if="!loadingMGps" class="btn btn-primary" @click="getKm">
                          GET KM GPS
                        </button>
                        <span v-else>Waiting...</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label"
                        >KM ODO</label
                      >
                      <div class="col-sm-5">
                        <input
                          type="text"
                          class="form-control"
                          id="period_code"
                          placeholder="KM ODO"
                          v-model="forms.km_odo"
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="period_code" class="col-sm-4 col-form-label"
                        >Route</label
                      >
                      <div class="col-sm-7">
                        <v-select
                        v-model="forms.route"
                        :options="fetchRoute"
                        return-object
                        @input="changeRoute"
                        >

                        </v-select>
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Estimasi Tiba di Pool</label
                      >
                      <div class="col-sm-7">
                        <date-range-picker
                          disabled
                          style="width: 100%"
                          :locale-data="locale"
                          v-model="tgl_estimasi_tiba"
                          :opens="opens"
                          :singleDatePicker="true"
                          :timePicker24Hour="true"
                          :timePicker="true"
                        >
                        </date-range-picker>
                        <div v-if="errors.tgl_estimasi_tiba">
                          <div
                            v-for="error in errors.tgl_estimasi_tiba"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 1</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan1" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div
                            v-for="error in errors.keluhan1"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 2</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan2" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div
                            v-for="error in errors.keluhan2"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 3</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan3" class="form-control">
                        <div v-if="errors.keluhan1">
                          <div
                            v-for="error in errors.keluhan3"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 4</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan4" class="form-control">
                        <div v-if="errors.keluhan4">
                          <div
                            v-for="error in errors.keluhan4"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 5</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan5" class="form-control">
                        <div v-if="errors.keluhan5">
                          <div
                            v-for="error in errors.keluhan5"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label for="defaultconfig" class="col-form-label col-sm-4"
                        >Keluhan 6</label
                      >
                      <div class="col-sm-7">
                        <input type="text" v-model="forms.keluhan6" class="form-control">
                        <div v-if="errors.keluhan6">
                          <div
                            v-for="error in errors.keluhan6"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              
                <div class="card-footer">
                  <button
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>

                  &nbsp;&nbsp;&nbsp;
                  <button
                    class="btn btn-primary float-end btn-sm"
                    type="submit"
                  >
                    <i class="link-icon" data-feather="save"></i>
                    {{ $t("submitFormTxt") }}
                  </button>

                
                </div>
              </form>
            </div>
          </div>
          <!--end::card-->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import multiselect from "vue-multiselect";

export default {
  name: "GerbangTolAdd",
  props: {},
  components: {
    DateRangePicker,
    multiselect: multiselect,
  },
  data() {
    return {
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      loadingMGps:false,
      truck:'',
      nmk_driver:'',
      fetchTruck:[],
      fppkDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      tgl_estimasi_tiba: {
        startDate: null,
        endDate: null,
      },

      fetchStatus: [],
      fetchRoute: [],
      fetchMerk: [],
      fetchJenisUnit: [],
      fetchDriver:[],
      km_gps:0,

      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: { pcs_qty: 1 },
      company: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.period_name = "";
      this.forms.price_gap = "";
      this.forms.price = "";
      this.forms.period_code = "";
      this.fppkDate = {
        startDate: null,
        endDate: null,
      };
      this.expiredDate = {
        startDate: null,
        endDate: null,
      };
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    onSearchChangeDriver(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-driver/select?driver_name=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI = this.$settings.endPoint + "company/select/code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    changeNopol(ev){
      this.forms.merk=ev.truck_brand
      this.forms.jenis_unit=ev.type_truck
      // this.getKm();
      // console.log(ev);
    },

    changeRoute(){
      
      console.log(this.fppkDate.startDate);

      if(this.forms.route=='Luar Kota'){

        this.tgl_estimasi_tiba={
          startDate:moment(this.fppkDate.startDate).add(48, 'hours'),
          endDate:moment(this.fppkDate.startDate).add(48, 'hours'),
        }

      }else{

        this.tgl_estimasi_tiba={
          startDate:moment(this.fppkDate.startDate).add(24, 'hours'),
          endDate:moment(this.fppkDate.startDate).add(24, 'hours'),
        }
      }
      
      // if(this.tanggal_fppk){
        
      // }
    },

    changeDriver(ev){
      this.forms.driverName=ev.driver_name;
      this.forms.hp_driver=ev.hp1;
      // console.log(ev);
    },

    loadStatus() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpStat`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas;
      });
    },


    loadFeatchUnit() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpRout`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRoute = response.data.datas;
      });
    },


    getKm() {
      this.loadingMGps=true;
      this.km_gps=0;
      var nopol=this.truck.vehicle_no;

      if(nopol==null || nopol=='' || nopol==undefined){
        this.loadingMGps=false;
        this.error('Vehicle No is empty');
      }else{

        const baseURI =
        this.$settings.endPointCt + `master-truck/get-lastkm/`+nopol;
        return this.$http.get(baseURI).then((response) => {
         
          if(response.data.status==200){
            
            
            if(response.data.datas?.messages==undefined){
              this.loadingMGps=false;
              //console.log(response.data.datas.last_km);
              this.km_gps=response.data.datas.last_km;
              // return this.forms.km_gps;
              // console.log(this.forms.km_gps);
            }else{
              this.loadingMGps=false;
              this.error(response.data.datas.messages);
            }

          }
        }) .catch((error) => {
          this.loadingMGps=false;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      }
      
    },


    loadMerk() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=fpMplan`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchMerk = response.data.datas;
      });
    },


    loadTruck() {
      const baseURI = this.$settings.endPointCt + `master-truck?select=select`;

      return this.$http.get(baseURI).then((response) => {
        let data = {
          category: " -- Select All Truck --",
          truck: response.data.datas.data,
        };

        this.fetchTruck.push(data);
      });
    },


    asyncSearchTruck(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            master_truck_id: item.master_truck_id,
            vehicle_no: item.vehicle_no,
          };
          this.fetchTruck.push(data);
        });
      });
    },


    

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "PengajuanFppkController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    customLabel(ev) {
            return `${ev.driver_id} – ${ev.driver_name}`
    },

    backForm() {
      window.location.href = "/fppk-pengajuan";
    },

    sendToCT(forms){
      const baseURI = this.$settings.endPointCt + "v1/master-truck/update-truck-status-for-fppk";
      this.$http
        .post(baseURI, forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.fppk_id);
            window.location.href =
              "/fppk-pengajuan/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    submitData() {
      // console.log(this.forms);
      // this.forms.company_id = this.company.company_id;
      this.forms.tanggal_fppk = moment(this.fppkDate.startDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.forms.tgl_estimasi_tiba = moment(this.tgl_estimasi_tiba.startDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.forms.nopol=this.truck.vehicle_no;

      this.forms.nmk_driver=this.nmk_driver.driver_id;
      this.forms.km_gps=this.km_gps;
      // console.log(this.forms);


      this.fade(true);
      const baseURI = this.$settings.endPointFppk + "v1/pengajuan-fppk/create";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.fppk_id);
            window.location.href =
              "/fppk-pengajuan/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },


    loadJenisUnit() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=truca`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchJenisUnit = response.data.datas;
      });
    },
    asyncJenisUnit(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=truca&search=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchJenisUnit = response.data.datas;
      });
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

  

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadStatus();
    this.loadJenisUnit();
    this.loadFeatchUnit();
    this.loadTruck()
    this.loadDriver()
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
  <style scoped></style>
  