import Index from "./requestBa.vue";
import Detail from "./requestBaDetail.vue";

let routesRequestMuat = [
    {
      path: '',
      meta: {
        title: "Chain System",
      },
      name: "requestPod",
      component: Index
    },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "requestPodDetail",
      component: Detail
    },
    
   
]

export default routesRequestMuat
  