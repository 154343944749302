<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Freepick</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <div class="modal-body">
           <form  ref="formElement" >
          <div class="row">

            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Customer</th>
                    <td>:</td>
                    <td>{{ data[0]?.company?.name }}</td>
                  </tr>
                  <tr>
                    <th>Order No</th>
                    <td>:</td>
                    <td>{{ data[0]?.order_no }}</td>
                  </tr>
                  <tr>
                    <th>Shipwithreff</th>
                    <td>:</td>
                    <td>{{ data[0]?.shipwith_ref }}</td>
                  </tr>
                  <tr>
                    <th>Tonnage</th>
                    <td>:</td>
                    <td>{{ data[0]?.tonnage }}</td>
                  </tr>
                  <tr>
                    <th>Pickup</th>
                    <td>:</td>
                    <td>{{ data[0]?.pick_plan }} - {{ data[0]?.pick_name }}</td>
                  </tr>
                  <tr>
                    <th>Drop</th>
                    <td>:</td>
                    <td>{{ data[0]?.dest_plan }} - {{ data[0]?.dest_name }}</td>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <td>:</td>
                    <td>
                      <div>
                        <div class="form-check form-check-inline">
                          <input value="own" type="radio" class="form-check-input" v-model="unit"
                            v-on:change="changeRadio">
                          <label class="form-check-label">
                            Own
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input value="vendor" type="radio" class="form-check-input" v-model="unit"
                            v-on:change="changeRadio">
                          <label class="form-check-label">
                            Vendor
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>

              </table>
            </div>
          </div>
          <br>
          <div v-if="unit == 'vendor'">
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Transporter </label>
                </div>
                <div class="col-lg-8">
                  <multiselect @input="cekData" @search-change="asyncTransporter" :custom-label="customLabelTransporter"
                    v-model="transporter" :options="fetchTransporter" placeholder="Please Select Transporter"
                    label="transporter">
                  </multiselect>
                  <div v-if="errors.transporter">
                    <div v-for="error in errors.transporter" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Type Truck </label>
                </div>
                <div class="col-lg-8">
                  <multiselect @input="cekDataTypeTruck" @search-change="asyncTypeTruck" v-model="typeTruck" :show-labels="false"
                    :options="fetchTypeTruck" placeholder="Please Select Type Truck" track-by="type_truck"
                    label="type_truck"></multiselect>
                  <div v-if="errors.type_truck">
                    <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Vehicle No </label>
                </div>
                <div class="col-lg-8">
                  <multiselect v-model="vehicle_no_vendor" :disabled="vehicle_no_disabled"
                    :options="fetchVehicleNoVendor" :custom-label="customLabelVehicleNoVendor"
                    placeholder="Please Select Vehicle No" label="vehicle_no" track-by="vehicle_no">
                    <span slot="noResult" @click="OpenNewVehicleNo">No Result, Please click here for new vehicle
                      no</span>
                  </multiselect>
                  <div v-if="errors.vehicle_no">
                    <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-1">
                  <span class="btn btn-danger btn-sm" :hidden="vehicle_no_disabled" @click="OpenNewVehicleNo"
                    v-if="!vehicle_no_vendor">Add</span>
                  <span class="btn btn-warning btn-sm" @click="OpenNewVehicleNo"
                    v-if="vehicle_no_vendor?.sob_status == 'FAILED' || vehicle_no_vendor?.sob_status == 'SEND'">Send</span>
                </div>

              </div>

            </div>


            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Driver </label>
                </div>
                <div class="col-lg-8">
                  <multiselect v-model="driver_vendor" :disabled="driver_disabled"
                    :custom-label="customLabelDriverVendor" :show-labels="false" :options="fetchDriverVendor"
                    placeholder="Please Select Driver" label="driver_id" track-by="driver_id">
                    <span slot="noResult" @click="OpenNewDriver">No Result, Please click here for new driver</span>
                  </multiselect>

                  <div v-if="errors.driver_id">
                    <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-1">
                  <span class="btn btn-danger btn-sm" :hidden="driver_disabled" @click="OpenNewDriver"
                    v-if="!driver_vendor">Add</span>
                  <span class="btn btn-warning btn-sm" @click="OpenNewDriver"
                    v-if="driver_vendor?.sob_status == 'FAILED' || driver_vendor?.sob_status == 'SEND'">Send</span>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Automatic Process
                  </label>
                </div>
                <div class="col-lg-8">
                  <multiselect v-model="auto_load" :show-labels="false" :options="fetch_auto_load_vendor"
                    :custom-label="customLabel2" placeholder="Please Select" label="name" track-by="name">
                  </multiselect>
                  <div v-if="errors.auto_load">
                    <div v-for="error in errors.auto_load" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Vehicle No </label>
                </div>
                <div class="col-lg-8">
                  <multiselect v-model="vehicle_no" :options="fetchVehicleNo" @search-change="asyncSearchVehicleNo"
                    placeholder="Please Select Vehicle No" label="vehicle_no" track-by="vehicle_no">
                  </multiselect>
                  <div v-if="errors.vehicle_no">
                    <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Driver </label>
                </div>
                <div class="col-lg-8">
                  <multiselect @search-change="asyncSearchDriver" v-model="driver" :custom-label="customLabel"
                    :show-labels="false" :options="fetchDriver" placeholder="Please Select Driver" label="driver_id"
                    track-by="driver_id"></multiselect>

                  <div v-if="errors.driver_id">
                    <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Automatic Process
                  </label>
                </div>
                <div class="col-lg-8">
                  <multiselect v-model="auto_load" :show-labels="false" :options="fetch_auto_load"
                    :custom-label="customLabel2" placeholder="Please Select" label="name" track-by="name">

                  </multiselect>
                  <div v-if="errors.auto_load">
                    <div v-for="error in errors.auto_load" :key="error" class="alert alert-primary" role="alert">
                      <i data-feather="alert-circle"></i>
                      {{ error }}
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


          </form>

          <div class="row">
            <div class="row mb-3">
              <div class="col-lg-3">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Customer Dest</label>
              </div>
              <div class="col-lg-8">
                <multiselect @search-change="asyncLookupCode" v-model="remark_dest_customer" :show-labels="false"
                  :options="fetchLookupCode" placeholder="Please Select Customer Dest" label="value" track-by="value">
                </multiselect>

                <div v-if="errors.remark_dest_customer">
                  <div v-for="error in errors.remark_dest_customer" :key="error" class="alert alert-primary"
                    role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>

          </div>




        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
            cancel
          </button>
          <button class="btn btn-success" type="submit" v-on:click="saveAssign">
            <span v-if="isLoading" class="spinner-border spinner-border-sm ms-auto" role="status"
              aria-hidden="true"></span>Save
          </button>
        </div>

      </div>
    </div>

    <modal-add-free-pick :showModal="showModalAdd" :data="dataSendToModalAdd" :type="type_" @closeModal="closeMyModal">
    </modal-add-free-pick>

  </div>


</template>

<script>
import multiselect from "vue-multiselect";
import ModalAddFreePick from "@/components/modal/modalAddFreePick.vue";

export default {
  components: {
    "multiselect": multiselect,
    "modal-add-free-pick": ModalAddFreePick
  },
  name: "ModalFreePick",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        console.log(this.data);
        this.vehicle_no = "";
        this.driver = "";
        this.vehicle_no_vendor = "";
        this.driver_vendor = "";
        this.vehicle_no_disabled = false;
        this.driver_disabled = false;
        this.fetch_auto_load = [];
        this.fetch_auto_load_vendor = [];
        this.auto_load = "";
        this.remark_dest_customer = "";
        this.loadLookupCode();
        this.loadDriver();
        this.loadVehicleNo();
        this.loadTransporter();
        this.loadTypeTruck();

        //   this.forms = {
        //       alert_id:this.data.alert_id,
        //       alert_setting_id:this.data.alert_setting_id,
        //       vehicle_no: this.data.vehicle_no,
        //       alert_time: this.data.alert_time,
        //       alert_type: this.data.alert_type,
        //       alert_value: this.data.alert_value,
        //       alert_subtype: this.data.alert_subtype,
        //       alert_off_reason: this.data.alert_off_reason,
        //     };
        this.fetch_auto_load.push(
          {
            name: "Yes"
          },
          {
            name: "No"
          },
          {
            name: "Manual"
          }
        );
        this.fetch_auto_load_vendor.push(
          
          {
            name: "No"
          },
          // {
          //   name: "Yes"
          // },
          {
            name: "Manual"
          }),
          this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      vehicle_no_disabled: false,
      driver_disabled: false,
      dataSendToModalAdd: '',
      type_: '',
      showModalAdd: false,
      classColapsed: '',
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      locale: [],
      fetch_auto_load: [],
      auto_load: "",
      report_date: [],
      pickup_date: "",
      delivery_date: "",
      groupByCategory: [],
      type_error: [],
      rows: [],
      prioritycek: [],
      activePriority_1: false,
      activePriority_2: false,
      activePriority_3: false,
      activePriority_4: false,
      activePriority_5: false,
      activePriority_6: false,
      activePriority_7: false,
      activePriority_8: false,
      activePriority_9: false,
      activePriority_10: false,
      dataPriority_1: [],
      dataPriority_2: [],
      dataPriority_3: [],
      dataPriority_4: [],
      dataPriority_5: [],
      dataPriority_6: [],
      dataPriority_7: [],
      dataPriority_8: [],
      dataPriority_9: [],
      dataPriority_10: [],
      fetchDriver: [],
      driver: "",
      unit: "own",
      fetchVehicleNo: [],
      vehicle_no: "",
      transporter: "",
      typeTruck: "",
      fetchTypeTruck: [],
      fetchTransporter: [],
      vehicle_no_vendor: "",
      driver_vendor: "",
      fetchVehicleNoVendor: [],
      fetchDriverVendor: [],
      fetchLookupCode: [],
      remark_dest_customer: '',

    };
  },
  methods: {
    resetForm() {
      this.forms = [];
    },

    reset() {
      this.groupByCategory = [];
      this.loadItems(this.data[0].transport_order_header_id);
    },

    customLabel(ev) {
      return `${ev.driver_id} – ${ev.driver_name}`
    },

    customLabelDriverVendor(ev) {
      return `${ev.driver_number} – ${ev.driver_name} | Status : ${ev.sob_status} `
    },

    customLabelVehicleNoVendor(ev) {
      return `${ev.vehicle_no} | Status : ${ev.sob_status} `
    },

    customLabel2(ev) {
      if (ev.name == 'Yes') {
        return "YES - Shipment - Load Id - Appoint Load";
      } else if (ev.name == 'No') {
        return "NO - Shipment - Load Id";
      } else {
        return "Manual - Shipment Only";
      }
    },
    customLabelTransporter(ev) {
      return ev.carrier_name;
    },
    loadDriver() {
      if(this.data.length!=0){
        const baseURI =
        this.$settings.endPointCt + `master-driver/select-freepick?branch=`+this.data[0]?.branch_name;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;

      });
      }
    
    },

    success2(msg) {
      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    },


    errorAlert(msg) {
      this.$swal({
        icon: 'danger',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    },

    OpenNewDriver() {
      this.showModalAdd = this.$route.name;
      this.type_ = "driver";
      this.dataSendToModalAdd = {
        transporter: this.transporter.carrier_code,
        vehicle_no: this.vehicle_no_vendor,
        driver: this.driver_vendor,
        typeTruck: this.typeTruck
      }
    },

    OpenNewVehicleNo() {
      this.showModalAdd = true;
      this.type_ = "truck";
      this.dataSendToModalAdd = {
        transporter: this.transporter.carrier_code,
        vehicle_no: this.vehicle_no_vendor,
        driver: this.driver_vendor,
        typeTruck: this.typeTruck
      }
    },

    asyncSearchDriver(ev) {
      this.fetchDriver = [];
      const baseURI =
        this.$settings.endPointCt + `master-driver/select?driver_id=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    closeMyModal() {
      this.showModalAdd = false;
      this.fetchTransporter = [];
      this.loadTransporter();

      this.loadForCarrier(this.transporter.carrier_code);
      // this.transporter=[];
      // this.vehicle_no_vendor="";
      // this.driver_vendor="";
      // this.typeTruck="";
      // this.fetchDriverVendor='';
      // this.fetchVehicleNoVendor='';
      //this.loadTransporter();
    },


    loadForCarrier(code) {
      const baseURI =
        this.$settings.endPointCt + `master-carrier/?carrier_code=` + code + `&status=active`;
      return this.$http.get(baseURI).then((response) => {
        this.transporter = response.data.datas.data[0];
        this.fetchDriverVendor = this.transporter.driver;
        //this.fetchVehicleNoVendor = this.transporter.truck;
        // console.log(response.data.datas.data);
        // this.forms.master_carrier_id=response.data.datas.data[0].master_carrier_id
        // console.log(this.forms.master_carrier_id);
      });
    },

    loadTransporter() {
      const baseURI =
        this.$settings.endPointCt + `master-carrier?status=active`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTransporter = response.data.datas.data.filter(carrier =>
          carrier.carrier_code !== '0001' && carrier.carrier_code !== '0000'
        );
      });
    },

    asyncTransporter(ev) {
      this.fetchTransporter = [];
      const baseURI =
        this.$settings.endPointCt + `master-carrier?carrier_name=` + ev + `&status=active`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTransporter = response.data.datas.data.filter(carrier =>
          carrier.carrier_code !== '0001' && carrier.carrier_code !== '0000'
        );
      });
    },
    loadTypeTruck() {
      const baseURI =
        this.$settings.endPointCt + `/type-truck?select=select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    asyncTypeTruck(ev) {
      this.fetchTypeTruck = [];
      const baseURI =
        this.$settings.endPointCt + `/type-truck?select=select&type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    changeRadio() {
      this.auto_load = null;
      this.vehicle_no = null;
      this.driver = null;
      this.transporter = null;
      this.typeTruck = null;
      this.errors = [];
      // console.log(this.unit);
      // if(this.unit=='vendor'){
      //   this.vehicle_no_disabled=true;
      //   this.driver_disabled=true;
      //   this.fetch_auto_load_vendor=[];
      //   this.fetch_auto_load_vendor.push(

      //   {
      //     name:"No"
      //   },
      //   {
      //     name:"Manual"
      //   });
      // }
    },
    setPriority() {

      console.log(this.groupByCategory[0]);
      // console.log(ev);
      // var data={
      //   vehicle_no:row2.vehicle_no,
      //   value:'',
      // }
      // console.log(data);
    },

    cekDataTypeTruck(ev){
      console.log(ev);
      this.vehicle_no_vendor="";
      this.fetchVehicleNoVendor = [];


      this.transporter.truck.forEach((data) => {
        if(ev.type_truck==data.truck_type
        ){
          this.fetchVehicleNoVendor.push({
          vehicle_no:data.vehicle_no,
          sob_status:data.sob_status
        });
        }

      });
    
     
    },

    cekData(ev) {

      console.log(ev);
      this.fetchDriverVendor = [];
      this.fetchVehicleNoVendor = [];
      this.fetchDriverVendor = ev.driver;
      //this.fetchVehicleNoVendor = ev.truck;
      //this.fetchTypeTruck=[];

      //ev.truck.forEach((data) => {
        //this.fetchTypeTruck.push({
          //type_truck_id: data.master_carrier_truck_id,
          //type_truck: data.truck_type,
          //vehicle_no:data.vehicle_no,
          //sob_status:data.sob_status
        //});
      //});

     

      if(ev.carrier_code=="IL30"){
        this.auto_load={
          name:"Yes"
        }
      }
     
     
    },

    saveAssign() {
      // this.isLoading = true;
      // var uniqueArray = this.planing.filter(function(item, pos) {
      //     return this.planing.indexOf(item) == pos;
      // }); 
      // console.log(uniqueArray);
      // console.log(this.driver);
      console.log(this.vehicle_no);
      var error = false;
      if (this.unit == 'vendor') {
        if (!this.transporter || this.transporter == "") {
          this.$set(this.errors, 'transporter', ["Please Select Transporter"]);
          this.errorAlert('Please Select Transporter')
          error = true;
        }
        if (!this.typeTruck || this.typeTruck == "") {
          this.$set(this.errors, 'type_truck', ["Please Select Type Truck"]);
          this.errorAlert('Please Select Type Truck')
          error = true;
        }
      }

      if (this.unit == 'own') {
        if (!this.vehicle_no || this.vehicle_no == "") {
          this.$set(this.errors, 'vehicle_no', ["Please Select Vehichle"]);
          this.errorAlert('Please Select Vehichle')
          error = true;
        }
        if (!this.driver || this.driver == "") {
          this.$set(this.errors, 'driver_id', ["Please Select Driver"]);
          this.errorAlert('Please Select Driver')
          error = true;
        }
      }
      if (!this.auto_load || this.auto_load == "") {
        this.isLoading = false;
        this.$set(this.errors, 'auto_load', ["Please Select Automatic Process"]);
        this.errorAlert('Please Select Automatic Process')
        error = true;
      }

      var transport_order_header_ids = [];
      if (this.data.length > 0) {
        for (let index = 0; index < this.data.length; index++) {
          const transport_order_header_id = this.data[index].transport_order_header_id;
          transport_order_header_ids.push(transport_order_header_id);
        }
      }
      // transport_order_header_ids.push(this.data.transport_order_header_id);

      if (!error) {
        var typeTruck = null;
        var carrier = null;
        var planing = {};
        if (this.unit == 'vendor') {
          console.log(this.typeTruck);
          typeTruck = this.typeTruck.type_truck;
          carrier = this.transporter.carrier_name;
          planing = {
            "transport_order_header_ids": transport_order_header_ids,
            "load_id": null,
            "auto_load": this.auto_load.name,
            "vehicle_no": this.vehicle_no_vendor != null ? this.vehicle_no_vendor.vehicle_no : '',
            "driver_nmk": this.driver_vendor != null ? this.driver_vendor.driver_number : '',
            "driver_name": this.driver_vendor != null ? this.driver_vendor.driver_name : '',
            "driver_number": this.driver_vendor != null && this.driver_vendor.driver_number != null ? this.driver_vendor.driver_number : '',
            "driver_hp": this.driver_vendor != null ? this.driver_vendor.driver_hp : '',
            "type_truck": typeTruck,
            "type_truck_id": this.typeTruck.type_truck_id,
            "branch_id": this.vehicle_no_vendor != null ? this.vehicle_no_vendor.branch_id : '',
            "branch_name": this.vehicle_no_vendor != null ? this.vehicle_no_vendor.branch_name : '',
            "carrier": carrier,
            "remark_dest_customer": this.remark_dest_customer.value,
          }
        } else {
          typeTruck = this.vehicle_no != null ? this.vehicle_no.type_truck_id : '';
          carrier = "0027-BAHANA PRESTASI";

          planing = {
            "transport_order_header_ids": transport_order_header_ids,
            "load_id": null,
            "auto_load": this.auto_load.name,
            "vehicle_no": this.vehicle_no != null ? this.vehicle_no.vehicle_no : '',
            "driver_nmk": this.driver != null ? this.driver.driver_id : '',
            "driver_name": this.driver != null ? this.driver.driver_name : '',
            "driver_number": this.driver != null && this.driver.driver_number != null ? this.driver.driver_number.driver_number : '',
            "driver_hp": this.driver != null ? this.driver.driver_hp : '',
            "type_truck": typeTruck,
            "type_truck_id": this.vehicle_no != null ? this.vehicle_no.type_truck_id : '',
            "branch_id": this.vehicle_no != null ? this.vehicle_no.branch_id : '',
            "branch_name": this.vehicle_no != null ? this.vehicle_no.branch_name : '',
            "carrier": carrier,
            "remark_dest_customer": this.remark_dest_customer.value,

          }
          // carrier  = this.vehicle_no!=null ? this.vehicle_no.carrier : '';
        }



        // var dataSendPlaning = {
        //   "customer_id": this.data[0].company_id,
        //   "customer": this.data[0].company.name,
        //   "shipment_number": this.data[0].order_no,
        //   "shipwith_ref": this.data[0].shipwith_ref,
        //   "tonage": this.data[0].tonnage,
        //   "cubic": this.data[0].cubic,
        //   "type_truck":typeTruck,
        //   "pickup_location": this.data[0].pick_name,
        //   "pickup_date": this.data[0].pick_plan,
        //   "drop_location": this.data[0].dest_name,
        //   "drop_date": this.data[0].dest_plan,
        //   "freepick":1,
        //   "planning": [planing]
        // };

        // console.log(dataSendPlaning);
        const baseURI = this.$settings.endPointCt + "planning/mass-create";

        return this.$http
          .post(
            baseURI,
            planing,
            { timeout: 10000 }
          )
          .then((response) => {

            if (response.status == 200) {
              this.transporter = null;
              this.typeTruck = null;
              this.isLoading = false;
              this.errors = [];
              this.success2('Success Save Freepick data !!');
              this.$emit("closeModal");
            }




          })
          .catch(error => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error(this.$t("check_internet_connection_txt"));
            } else {

              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  // this.$router.push('/server-error');
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else {
                  this.$router.push('/page-not-found');
                }
              }

            }

          });
      }

    },

    loadVehicleNo() {
      // const baseURI =
      //   this.$settings.endPointCt + `master-truck`;
      // return this.$http.get(baseURI).then((response) => {
      //   this.fetchVehicleNo = response.data.datas.data;

      // });

      const userDatas = this.$getUserInfo();
      let role = userDatas.sub.role;
      // this.permision_role = userDatas.sub.permision_role;
      this.fetchVehicleNo = [];
      let role_truck_permission = role.role_truck_permission;
      console.log(role_truck_permission);
      if (role_truck_permission.length > 0) {
        for (let index = 0; index < role_truck_permission.length; index++) {


          this.fetchVehicleNo.push({
            vehicle_no: role_truck_permission[index].master_truck.vehicle_no,
            type_truck_id: role_truck_permission[index].master_truck.type_truck_id,
            branch_id: role_truck_permission[index].master_truck.branch_id,
            branch_name: role_truck_permission[index].master_truck.branch_name,
          });

        }
      } else {
        const baseURI =
          this.$settings.endPointCt + `master-truck`;
        return this.$http.get(baseURI).then((response) => {
          this.fetchVehicleNo = response.data.datas.data;

        });
      }


      // role.role_truck_permission.forEach(function (role_truck) {
      //   // console.log(role_truck.master_truck.vehicle_no);
      //   this.fetchVehicleNo.push({
      //     vehicle_no:role_truck.master_truck.vehicle_no
      //   });
      //     // this.fetchVehicleNo.push({
      //     //   vehicle_no:role_truck.master_truck.vehicle_no
      //     // });
      // });

    },

    asyncSearchVehicleNo(ev) {

      const userDatas = this.$getUserInfo();
      let role = userDatas.sub.role;
      // this.permision_role = userDatas.sub.permision_role;
      this.fetchVehicleNo = [];
      let role_truck_permission = role.role_truck_permission;
      console.log(role_truck_permission);
      if (role_truck_permission.length > 0) {
        for (let index = 0; index < role_truck_permission.length; index++) {
          const vehicle_no = role_truck_permission[index].master_truck.vehicle_no;
          if (vehicle_no == ev) {
            this.fetchVehicleNo.push({
              vehicle_no: vehicle_no
            });
          }

        }
      } else {
        const baseURI =
          this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;
        return this.$http.get(baseURI).then((response) => {
          this.fetchVehicleNo = response.data.datas.data;
        });

      }



    },


    loadLookupCode() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup/index-view?unique_id=custan`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchLookupCode = response.data.datas.data;

      });
    },

    asyncLookupCode(ev) {
      this.fetchLookupCode = [];
      const baseURI =
        this.$settings.endPointCt + `master-lookup/index-view?unique_id=custan&value=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchLookupCode = response.data.datas.data;

      });
    },



    loadItems(transport_order_header_id) {
      this.rows = [];
      const baseURI = this.$settings.endPointCt + "truck-priority/select/" + transport_order_header_id;
      return this.$http
        .get(
          baseURI,
          { timeout: 10000 }
        )
        .then((response) => {

          this.rows = response.data.datas;






          // this.totalRecords = response.data.datas.total;
          // this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },




  },
  mounted() {



    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'readonly') {
            const inputs = formElement.querySelectorAll('.multiselect__input');
            inputs.forEach(input => {
              input.removeAttribute('readonly');  // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll('.multiselect__input');
      inputs.forEach(input => {
        observer.observe(input, { attributes: true });
      });
    }

    //tail(this.data);
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>