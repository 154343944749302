<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RasioEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start">Rasio</h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Kode Rasio</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ratio_kode"
                          readonly
                          v-model="forms.ratio_kode"
                          required
                        />
                        <div v-if="errors.ratio_kode">
                          <div
                            v-for="error in errors.ratio_kode"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Golongan</label
                        >
                        <v-select
                          disabled
                          :options="fetchGolongan"
                          v-model="golongan"
                          label="name"
                          return-object
                        ></v-select>
                        <div v-if="errors.golongan">
                          <div
                            v-for="error in errors.golongan"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Nama Rasio</label
                        >
                        <input
                          readonly
                          type="text"
                          class="form-control"
                          id="ratio_name"
                          v-model="forms.ratio_name"
                          required
                        />
                        <div v-if="errors.ratio_name">
                          <div
                            v-for="error in errors.ratio_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Komisi Supir</label
                        >
                        <CurrencyInput
                              v-model.number="forms.driver_commision"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Komisi Supir"
                            />
                        <div v-if="errors.driver_commision">
                          <div
                            v-for="error in errors.driver_commision"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Cabang</label
                        >

                        <v-select
                          disabled
                          :options="fetchCabang"
                          v-model="cabang"
                          label="branch_name"
                          return-object
                          @search="asyncSearchBranch"
                        ></v-select>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="nama_rasio"
                          
                          v-model="forms.nama_rasio"
                          required
                        /> -->
                        <div v-if="errors.branch_id">
                          <div
                            v-for="error in errors.branch_id"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Deposit</label
                        >
                        <CurrencyInput
                              v-model.number="forms.driver_deposit"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Deposit"
                            />
                        <div v-if="errors.driver_deposit">
                          <div
                            v-for="error in errors.driver_deposit"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Type Truck</label
                        >

                        <v-select
                          disabled
                          :options="fetchCategoryTruck"
                          v-model="forms.truck_category"
                          return-object
                        ></v-select>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="nama_rasio"
                          
                          v-model="forms.nama_rasio"
                          required
                        /> -->
                        <div v-if="errors.truck_category">
                          <div
                            v-for="error in errors.truck_category"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label"
                          >Self Insurance</label
                        >
                        <CurrencyInput
                              v-model.number="forms.driver_self_insurance"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Self Insurance"
                            />
                        <div v-if="errors.driver_self_insurance">
                          <div
                            v-for="error in errors.driver_self_insurance"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row card">
                          <div class="card-header">
                            <h5 class="float-start">
                              Rasio <span class="wajib">*</span>
                            </h5>

                            <button
                              class="btn btn-success btn-icon text-white fw-bolder btn-icon-text float-end"
                              @click="addField('rasio')"
                              type="button"
                            >
                              <i class="link-icon" data-feather="plus"></i>
                            </button>
                          </div>

                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table" style="height: 300px">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th style="width: 500px">Nama Rasio</th>
                                    <th>Rasio</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(input, index) in this
                                      .detailAllRatio"
                                    :key="`rasio-${index}`"
                                  >
                                    <th>{{ index + 1 }}</th>

                                    <td width="200px">
                                      <input
                                      readonly
                                        class="form-control"
                                        v-model="input.name"
                                        id="name"
                                      />
                                    </td>

                                    <td width="200px">
                                      <input
                                        
                                        class="form-control"
                                        type="number"
                                        v-model="input.ratio"
                                        id="ratio"
                                        step="any"
                                      />
                                    </td>

                                    <td>
                                      <!--          Remove Svg Icon-->
                                      <svg
                                       v-if="input.rm_ratio_master_detail_id==null || input.rm_ratio_master_detail_id==''"
                                        @click="
                                          removeField(index, detailAllRatio)
                                        "
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        class="ml-2 cursor-pointer"
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          fill="red"
                                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                        />
                                      </svg>
                                    </td>
                                  </tr>
                                </tbody>

                                <tfoot></tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="row card" style="margin-left: 5px">
                          <div class="card-header">
                            <h5 class="float-start">
                              Biaya Kapal <span class="wajib">*</span>
                            </h5>

                            <button
                              class="btn btn-success btn-icon text-white fw-bolder btn-icon-text float-end"
                              @click="addField('kapal')"
                              type="button"
                            >
                              <i class="link-icon" data-feather="plus"></i>
                            </button>
                          </div>

                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table" style="height: 300px">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th style="width: 500px">
                                      Nama Penyebrangan
                                    </th>
                                    <th>Biaya Kapal (Rp)</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(input, index) in this
                                      .detailAllKapal"
                                    :key="`kapal-${index}`"
                                  >
                                    <th>{{ index + 1 }}</th>

                                    <td width="200px">
                                      <input
                                        class="form-control"
                                        v-model="input.name"
                                        id="name"
                                      />
                                    </td>

                                    <td width="200px">
                                      <input
                                        class="form-control"
                                        type="number"
                                        v-model="input.price"
                                        id="price"
                                      />
                                    </td>

                                    <td>
                                      <! --          Remove Svg Icon- ->
                                      <svg
                                        @click="
                                          removeField(index, detailAllKapal)
                                        "
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        class="ml-2 cursor-pointer"
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          fill="red"
                                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                        />
                                      </svg>
                                    </td>
                                  </tr>
                                </tbody>

                                <tfoot></tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                      <!-- <span
                        class="btn btn-success float-end btn-sm"
                        style="margin-right: 5px"
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator"></i>
                        Calculate
                      </span> -->
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import CurrencyInput from '@/components/_partials/inputCurrency.vue';

export default {
  name: "PackageEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    CurrencyInput
  },
  data() {
    return {
      detailAllRatio: [],
      detailAllKapal: [],
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: {},
      company: [],
      fetchGolongan: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
        {
          name: 4,
        },
        {
          name: 5,
        },
      ],
      golongan: "",
      fetchCabang: [],
      fetchCategoryTruck: [],
      truck_category: "",
      cabang: "",
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms = [];
    },

    changeCompany(ev) {
      this.forms.companyId = ev;
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=10";
      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadCompanyNotLinc() {
      const baseURI = this.$settings.endPoint + `company/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/select?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    asyncSearchCompanyNotLinc(ev) {
      const baseURI =
        this.$settings.endPoint +
        "company?name=" +
        ev +
        "&code=" +
        this.companyCode;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    addField(ev) {
      //fieldType.push({ value: "" });
      if (ev == "rasio") {
        this.detailAllRatio.push({
          rm_ratio_master_detail_id:"",
          name: "",
          ratio: "",
        });
      }

      if (ev == "kapal") {
        this.detailAllKapal.push({
          name: "",
          ratio: "",
        });
      }
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-ratio-master/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.detailAllKapal = this.forms.ferry;
        this.detailAllRatio = this.forms.detail;
        this.cabang = {
          branch_id: this.forms.branch_id,
          branch_name: this.forms.branch_name,
        };

        // this.truck_category = {
        //   type_truck: this.forms.truck_category,
        // };

        this.golongan = {
          name: this.forms.class,
        };
        //this.forms.companyId=response.data.datas.company;
        //this.forms.companyId.push(response.data.datas.company);
        //console.log(this.fetchCompany);
        // this.forms.companyId = {
        //   company_id: this.forms.company_id,
        //   name: this.forms.company.name,
        // };
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },
    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi RASIO ?",
        text: "Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan prubahan rasio ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        console.log(result);

        this.fade(true);
        const baseURI =
        this.$settings.endPoint +
        "rm-ratio-master/calculation/" +
        this.forms.rm_ratio_master_id;
      this.$http
        .patch(baseURI,{ timeout: 3600000 })
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            this.loadData(this.forms.rm_ratio_master_id);
            // var params = this.$onRandom(this.forms.rm_fuel_master_id);
            // window.location.href =
            //   "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              // this.resultError(error.response.data.errors);
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });

      });
    },
    loadBranch() {
      const baseURI = this.$settings.endPointCt + "branch?limit=10";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCabang = response.data.datas.data;
      });
    },

    asyncSearchBranch(ev) {
      const baseURI = this.$settings.endPointCt + "branch?branch_name=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCabang = response.data.datas.data;
      });
    },
    // asyncTypeTruck(ev) {
    //   const baseURI = this.$settings.endPointCt + "type-truck?type_truck=" + ev;
    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchCategoryTruck = response.data.datas.data;
    //   });
    // },
    // loadTruckType() {
    //   const baseURI = this.$settings.endPointCt + "type-truck";
    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchCategoryTruck = response.data.datas.data;
    //     // let data = {
    //     //   category: " -- Select All Type Truck --",
    //     //   type_truck: response.data.datas.data,
    //     // };
    //     // this.fetchCategoryTruck.push(data);
    //   });
    // },

    asyncTypeTruck(ev) {
      const baseURI = this.$settings.endPointCt + `master-lookup?unique_id=truca&search=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCategoryTruck = response.data.datas;
      });
    },
    loadTruckType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=truca`;
      // const baseURI = this.$settings.endPointCt + "type-truck";
      return this.$http.get(baseURI).then((response) => {
        this.fetchCategoryTruck = response.data.datas;
        // let data = {
        //   category: " -- Select All Type Truck --",
        //   type_truck: response.data.datas.data,
        // };
        // this.fetchCategoryTruck.push(data);
      });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmRatioMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/master-rasio";
    },

    submitData() {
      this.fade(true);
      this.forms.detail = this.detailAllRatio;
      // this.forms.ferry = this.detailAllKapal;

      this.forms.branch_id = this.cabang.branch_id;
      this.forms.branch_name = this.cabang.branch_name;
      // this.forms.truck_category = this.truck_category.type_truck;
      this.forms.class = this.golongan.name;

      const baseURI =
        this.$settings.endPoint +
        "rm-ratio-master/update/" +
        this.forms.rm_ratio_master_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.rm_ratio_master_id);
            window.location.href =
              "/master-rasio/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadBranch();
    this.loadTruckType();
  },
};
</script>
<style scoped></style>
