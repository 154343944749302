import requestMuatIndex from "@/components/lincMobile/requestMuat/requestMuat.vue";
import requestMuatDetail from "@/components/lincMobile/requestMuat/requestMuatDetail.vue";

let routesRequestMuat = [
    {
      path: '',
      meta: {
        title: "Chain System",
      },
      name: "requestMuatIndex",
      component: requestMuatIndex
    },
    {
      path: 'detail/:id',
      meta: {
        title: "Chain System",
      },
      name: "requestMuatDetail",
      component: requestMuatDetail
    },
    
   
]

export default routesRequestMuat
  