import { render, staticRenderFns } from "./MelAdd.vue?vue&type=template&id=486e8d65&scoped=true"
import script from "./MelAdd.vue?vue&type=script&lang=js"
export * from "./MelAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486e8d65",
  null
  
)

export default component.exports