import { render, staticRenderFns } from "./GerbangTolEdit.vue?vue&type=template&id=0dbf34d5&scoped=true"
import script from "./GerbangTolEdit.vue?vue&type=script&lang=js"
export * from "./GerbangTolEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dbf34d5",
  null
  
)

export default component.exports