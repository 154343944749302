<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <form
                  class="forms-sample"
                  @submit.prevent="submitData"
                  method="POST"
                >
                  <div class="card-header">
                    <h4 style="margin-right: 5px" class="float-start mx-2">
                      Request Berita Acara
                    </h4>
                  </div>
                  <div class="card-body">
                    <div class="row my-3">
                      <div class="col-sm-5" id="idDivKiri">
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Jenis Request</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Jenis Request"
                              value="Laporan Berita Acara"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Load Id</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Load Id"
                              v-model="forms.load_id"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Shipwithreff</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Shipwithreff"
                              v-model="forms.shipwith_ref"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Nama Driver</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Nama Driver "
                              v-model="forms.driver_name"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >NMK Driver</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="NMK Driver "
                              v-model="forms.driver_nmk"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Nopol</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Nopol "
                              v-model="forms.vehicle_no"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Type Truck</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Type Truck "
                              v-model="forms.truck_type"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Pick Plan</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Pick Plan"
                              v-model="forms.pick_plan"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Pick Name</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Pick Name "
                              v-model="forms.pick_name"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Drop Plan</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Drop Plan"
                              v-model="forms.dest_plan"
                            />
                          </div>
                        </div>
                        <div class="form-group row my-2">
                          <label
                            for="exampleInputUsername2"
                            class="col-sm-5 col-form-label"
                            >Drop Name</label
                          >
                          <div class="col-sm-7">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Drop Name"
                              v-model="forms.dest_name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <div class="card">
                          <div class="card-header">List Berita Acara</div>
                          <div class="card-body" id="containerImage">
                            <div
                              class="row"
                              style="overflow-y: auto; max-height: 500px"
                            >
                              <div
                                class="col-3 my-2 mx-2 shadow"
                                v-for="(list, index) in forms.ba"
                                :key="'ListBa-' + index"
                              >
                                <div
                                  class="imageContainer"
                                >
                                  <img
                                    class="imagePod"
                                    style="
                                    max-width: inherit;
                                    max-height:120px;
                                    "
                                    :src="list.value"
                                  />
                                  <!-- <img
                                    class="imagePod"
                                    style="
                                      max-width: inherit;
                                      max-height: 300px;
                                    "
                                    :src="list.value"
                                  /> -->
                                    <div class="overlay" @click="showImage(list)">
                                    <a href="#" class="icon" title="User Profile">
                                      <i class="mdi mdi-magnify-plus"></i>
                                    </a>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Status : </label>
                                  <input
                                    disabled
                                    class="form-control"
                                    :value="list.status"
                                  />
                                </div>
                                <div class="form-group">
                                  <label>Remarks : </label>
                                  <textarea
                                    class="form-control"
                                    v-model="list.remarks"
                                  ></textarea>
                                </div>
                                <div class="row my-2">
                                  <div class="col-sm-12 text-center">
                                    <button
                                      class="btn btn-success btn-icon mx-1 btn-xs float-left"
                                      type="button"
                                      :disabled="
                                        list.status.toUpperCase() == 'APPROVE'
                                      "
                                      @click="approve(list)"
                                    >
                                      <!-- Approve -->
                                      <i class="mdi mdi-check"></i>
                                    </button>
                                    <button
                                      class="btn btn-danger btn-icon mx-1 btn-xs float-left"
                                      type="button"
                                      :disabled="
                                        list.status.toUpperCase() == 'REJECT'
                                      "
                                      @click="reject(list)"
                                    >
                                      <!-- Reject -->
                                      <i class="mdi mdi-close-thick"></i>
                                    </button>
                                    <button
                                      class="btn btn-warning btn-icon mx-1 btn-xs float-left"
                                      type="button"
                                      @click="downloadImage(list)"
                                    >
                                      <!-- Download -->
                                      <i class="mdi mdi-download"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <!-- <button
                      class="btn btn-primary float-end btn-sm"
                      type="submit"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitFormTxt") }}
                    </button> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- all props & events -->
          <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "requestMuatDetail",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // DirectionsRenderer
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: [],
      visible: false,
      index: 0, // default: 0

      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      forms: [],

      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      waypnt: [],
      markers: [],
    };
  },
  watch: {},
  methods: {
    approve(data) {
      let send = {
        isapprove: "yes",
        remarks: data.remarks,
      };

      this.submitData(send, data.m_berita_acara_id);
    },
    reject(data) {
      let send = {
        isapprove: "no",
        remarks: data.remarks,
      };

      this.submitData(send, data.m_berita_acara_id);
    },

    handleHide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
    showImage(data) {
      console.log(data);
      this.imgs = data.value;
      this.show();
    },
    downloadImage(data) {
      let arrFile = data.value.split("/");
      var filename = arrFile[arrFile.length - 1];
      fetch(data.value)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    backForm() {
      window.location.href = "/request-ba";
    },
    submitData(send, id) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.loading();
          const baseURI =
            this.$settings.endPointLincMobile + "mobile/m-berita-acara/update/" + id;
          this.$http
            .post(baseURI, send)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadData();
                // window.location.href = "/request-pod";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
      // console.log(this.forms);
    },
    createData() {
      window.location.href = "/master-bbm/add";
    },

    uploadData() {
      window.location.href = "/master-bbm/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.rm_fuel_master_id);
      window.location.href = "/master-bbm/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.rm_fuel_master_id);
      window.location.href = "/master-bbm/detail/" + params;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    //ini function button
    filterSearch() {
      const periodCodeFilterElement = document.querySelector(
        '[name="vgt-period_code"]'
      ); //ini setiap field pasti ada vgt-

      const periodNameFilterElement = document.querySelector(
        '[name="vgt-period_name"]'
      ); //ini setiap field pasti ada vgt-

      var params = {
        columnFilters: {
          period_code: periodCodeFilterElement.value,
          period_name: periodNameFilterElement.value,
          price: "",
          price_gap: "",
          effective_date: "",
          expired_date: "",
        },
        page: 1,
      }; // params yang di kirim

      this.updateParams(params); //ini untuk update params vue good table
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MobileShipmentController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });
      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
    getTruckLocation() {
      const baseURI = this.$settings.endPointCt + "real-monitoring/get";
      this.isLoading = true;
      return this.$http
        .get(baseURI + `?vehicle_no=${this.forms.vehicle_no}`)
        .then((response) => {
          this.loading();
          if (response.data.status == 200) {
            let data = response.data.datas.data[0];
            this.center = {
              lat: data.latitude,
              lng: data.longitude,
            };
            this.markers.push({
              position: {
                lat: data.latitude,
                lng: data.longitude,
              },
            });
          }
        });
    },
    checkData() {
      let params = this.$onBehind(this.$route.params.id);
      let idDetail = params[0];
      if (
        localStorage["detailDatas"] !== undefined &&
        localStorage["detailDatas"] !== 0 &&
        localStorage["detailDatas"] !== null &&
        localStorage["detailDatas"] !== false &&
        localStorage["detailDatas"] !== ""
      ) {
        let details = JSON.parse(localStorage.detailDatas);
        if (idDetail === details.m_load_id) {
          this.forms = details;
        } else {
          this.$router.push("/request-pod");
        }
      } else {
        this.$router.push("/request-pod");
      }
    },
    loadData() {
      let params = this.$onBehind(this.$route.params.id);
      let idDetail = params[0];
      const baseURI =
        this.$settings.endPointLincMobile + "mobile/m-berita-acara/detail/" + idDetail;
      return this.$http.get(baseURI).then((response) => {
        console.log(response);
        this.forms = response.data.datas;
        // this.loading();
        // this.rows = response.data.datas.data;
        // this.totalRecords = response.data.datas.total;
      });
    },
  },
  events: {},
  created: function () {
    // this.checkData();
  },
  mounted() {
    this.fetchIt();
    this.loading();
    this.loadData();
  },
};
</script>

<style scoped>
.imagePod {
  display: block;
  margin: auto;
}
.overlay {
  /* position: absolute;
  top:50%;
  left:50%; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: black;
}

.imageContainer{
  position: relative;
  max-width: 100%;
  height:120px;
}

.imageContainer:hover .overlay {
  opacity: 0.5;
}

.icon {
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
</style>
