<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RasioDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left:25px">Detail BBM</h6>

                  <button class="btn btn-light float-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left:20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0 ">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>



                  </div>

                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">{{ $t('periodCodeTxt') }}</label>
                        <input type="text" class="form-control" id="kode_periode" readonly v-model="forms.period_code"
                          required />
                        <div v-if="errors.period_code">
                          <div v-for="error in errors.period_code" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">{{ $t('priceTxt') }}</label>
                        <input type="number" class="form-control" id="harga" disabled v-model="forms.price" required />
                        <div v-if="errors.price">
                          <div v-for="error in errors.price" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                    </div>



                    <div class="row mb-3">

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">{{ $t('periodNameTxt') }}</label>
                        <input type="text" class="form-control" id="nama_periode" disabled v-model="forms.period_name"
                          required />
                        <div v-if="errors.period_name">
                          <div v-for="error in errors.period_name" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">{{ $t('priceGapTxt') }}</label>
                        <input type="number" class="form-control" id="selisih" disabled v-model="forms.price_gap"
                          required />
                        <div v-if="errors.price_gap">
                          <div v-for="error in errors.price_gap" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                    </div>


                    <div class="row mb-3">

                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Effective Date</label>

                        <date-range-picker style="width: 100%" :locale-data="locale" v-model="effectiveDate"
                          :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" disabled>
                        </date-range-picker>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="efective_date"
                          disabled
                          
                          v-model="effectiveDate.startDate"
                          required
                        /> -->
                        <!-- <div v-if="errors.period_name">
                          <div
                            v-for="error in errors.period_name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div> -->
                      </div>


                      <div class="col-lg-6">
                        <label for="defaultconfig" class="col-form-label">Expired Date</label>
                        <date-range-picker style="width: 100%" :locale-data="locale" v-model="expiredDate" :opens="opens"
                          :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true" disabled>
                        </date-range-picker>
                        <!-- <div v-if="errors.price_gap">
                          <div
                            v-for="error in errors.price_gap"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div> -->
                      </div>

                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a v-if="!isLoading && permision_role.method_delete" class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="deleteData()">
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a v-if="!isLoading && permision_role.method_update" class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="editData()">
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>


                      <span class="btn btn-success float-end " style="margin-right: 5px"
                        v-if="!isLoading && forms.is_calculate == 0 && showBtnCalculate == true" @click="calculate()">
                        <i class="mdi mdi-calculator" style="font-size:16px"></i>
                        Calculate
                      </span>


                      <span class="btn btn-danger float-end " style="margin-right: 5px"
                        v-if="!isLoading && forms.is_calculate == 1 && showBtnCalculate == true" @click="canceled()">
                        <i class="mdi mdi-close" style="font-size:16px"></i>
                        Cancel Calculate
                      </span>


                      <!-- <span
                      
                        class="btn btn-success float-end "
                        style="margin-right: 5px"
                        v-if="forms.is_calculate==0 && showBtnCalculate==true"
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator" style="font-size:16px"></i>
                        Calculate
                      </span> -->


                      <!-- <span
                      
                      class="btn btn-danger float-end "
                      style="margin-right: 5px"
                      v-if="forms.is_calculate==1 && showBtnCalculate==true"
                      @click="canceled()"
                    >
                      <i class="mdi mdi-close" style="font-size:16px"></i>
                      Cancel Calculate
                    </span> -->
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "BBMDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
  },
  data() {
    return {
      effectiveDate: {
        startDate: null,
        endDate: null,
      },
      expiredDate: {
        startDate: null,
        endDate: null,
      },
      showBtnCalculate: false,
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.package_code = "";
      this.forms.package_name = "";
      this.forms.company_id = "";
      this.forms.cs_code = "";
      this.forms.cs_qty = "";
      this.forms.ip_code = "";
      this.forms.ip_qty = "";
      this.forms.ot_code = "";
      this.forms.ot_qty = "";
      this.forms.pl_code = "";
      this.forms.pl_qty = "";
      this.forms.pcs_code = "";
    },


    cek_message(status, msg) {
      if (status == '200') {
        this.success(msg);
      }
    },


    cekShowBtnCalculate() {
      const today = new Date();
      // Pemeriksaan apakah tanggal hari ini berada di antara effectiveDate dan expiredDate
      if (today >= this.effectiveDate.startDate && today <= this.expiredDate.startDate) {
        this.showBtnCalculate = true;
      } else {
        this.showBtnCalculate = false;
      }
    },

    editData() {
      var params = this.$route.params.id
      window.location.href = "/master-bbm/edit/" + params;
    },


    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "rm-fuel-master/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/master-bbm";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=100";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-fuel-master/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response);
        this.forms = response.data.datas;


        this.effectiveDate = {
          startDate: new Date(this.forms.effective_date),
          endDate: new Date(this.forms.effective_date),
        };
        this.expiredDate = {
          startDate: new Date(this.forms.expired_date),
          endDate: new Date(this.forms.expired_date),
        };


        this.cekShowBtnCalculate();
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi BBM ?",
        // text: "Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        html: `<p>Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini</p><br><input type="checkbox" id="kalkulasiRekon" > <label for="kalkulasiRekon">Kalkulasi Rekon</label>
                    `,
      }).then((result) => {

        const checkbox = document.getElementById('kalkulasiRekon');
        this.rekon = checkbox.checked;

        if (result.isConfirmed) {
          this.fade(true);

          const baseURI = this.$settings.endPoint + "rm-fuel-master/calculation/" + this.forms.rm_fuel_master_id;

          // var encode = this.$onRandom(this.forms.rm_ferry_master_id);

          this.$http
            .patch(baseURI, {
              "rekon": this.rekon == true ? 'yes' : 'no',
               // yes or no
              // "link_page_pay_rate_archiving_detail_id" : window.location.origin+"/payable-rate/archiving/detail/"+encode
            },{ timeout: 3600000 })
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {

                this.success(response.data.datas.messages);

                //window.history.back();
                this.loadData(this.forms.rm_fuel_master_id);
                //this.resetForm();

              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  // this.resultError(error.response.data.errors);
                  if (this.errors.message) {
                    this.error(this.errors.message);
                  } else {
                    this.resultError(error.response.data.errors);
                  }
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });
        }



      });
    },


    canceled() {
      this.$swal({
        title: "Apakah Anda Yakin akan Cancel Calculate BBM ?",
        text: "Semua Record Payable akan mengalami kembali sesuai harga BBM sebelumnya ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      
      }).then((result) => {

        if (result.isConfirmed) {
          console.log(result);
          this.fade(true);
          const baseURI =
            this.$settings.endPoint +
            "rm-fuel-master/rollback-calculation/" +
            this.forms.rm_fuel_master_id;
          this.$http
            .patch(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                //this.resetForm();
                this.errors = [];
                this.success(response.data.datas.messages);

                //window.history.back();
                this.loadData(this.forms.rm_fuel_master_id);
                // var params = this.$onRandom(this.forms.rm_fuel_master_id);
                // window.location.href =
                //   "/master-bbm/detail/" + params + "?status=200&msg=Successfully";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  // this.resultError(error.response.data.errors);
                  if (this.errors.message) {
                    this.error(this.errors.message);
                  } else {
                    this.resultError(error.response.data.errors);
                  }
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              //this.resetForm();
            });

        }
      }

      );
    },


    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company?code=${this.companyCode}`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFuelMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/master-bbm";
    },

    submitData() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourData"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.fade(true);

          //let formData = new FormData();

          const baseURI = this.$settings.endPoint + "package/edit";
          this.$http
            .patch(baseURI, this.forms)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.resetForm();
                this.errors = [];
                //console.log(response.data.datas.user_id);
                var params = this.$onRandom(response.data.datas.package_id);
                window.location.href = "/package/detail/" + params;
                // this.success('Berhasil');
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
              this.resetForm();
            });
        }
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = false;
        }
      })
    },

   

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped></style>