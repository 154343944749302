<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start mx-2">
                    Request Leave
                  </h4>
                  <div class="float-start">
                    <div
                      class="input-group flatpickr wd-250 me-2 mb-2 mb-md-0"
                      id="dashboardDate"
                    >
                      <date-range-picker
                        style="width: 100%"
                        control-container-class="form-control"
                        :locale-data="locale"
                        v-model="dateFilter"
                        :opens="opens"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :showWeekNumbers="false"
                      >
                      </date-range-picker>
                    </div>
                  </div>
                  <button type="button" style="max-height: 40px;" @click.prevent="loadItems"
                      class="btn btn-primary btn-icon-text mb-2 mb-md-0 btn-block btn-floating float-start">
                      GO
                  </button>
                  
                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>

                  <!-- <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="filterSearch()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                   Filter Search
                  </button>
                -->
                  <!-- <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    disabled
                    @click.prevent="calculate()"
                  >
                    <i class="mdi mdi-calculator" style="font-size:16px"></i>
                    Calculate
                  </button> -->
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-region"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'type'">
                        Muat
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>
<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

export default {
  name: "BbmIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
  },
  data() {
    const startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    const endDate = moment();

    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,

      locale: {
        format: "yyyy-mm-dd",
      },
      opens: "center",
      dateFilter: {
        startDate,
        endDate,
      },

      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: this.$t("statusTxt"),
          field: "attendance_type",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("statusTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t("decisionTxt"),
          field: "leave_decission",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By " + this.$t("decisionTxt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t('driver_name'),
          field: "driver_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+this.$t('driver_name'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: this.$t('driver_nmk'),
          field: "driver_nmk",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+ this.$t('driver_nmk'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        
        {
          label: this.$t('vehicle_no'),
          field: "vehicle_no",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+ this.$t('vehicle_no'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

       

        {
          label: this.$t('txtAlasan'),
          field: "reason",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By "+this.$t('txtAlasan'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

       

        {
          label: this.$t('pool_name'),
          field: "pool_name",
          filterOptions: {
            enabled: true, // enable filter  for this column
            placeholder: "Filter By" +this.$t('pool_name'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: this.$t('locationTxt'),
          field: "location",
          filterOptions: {
            enabled: true, // enable filter  for this column
            placeholder: "Filter By" +this.$t('locationTxt'), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    downloadData() {
      let start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD');
      let end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD');
      const baseURI = this.$settings.endPointLincMobile + "mobile/shipment/muat";
      
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${start}&end=${end}&approve_drop=${this.serverParams.columnFilters.approve_drop}&load_id=${this.serverParams.columnFilters.load_id}&shipwith_ref=${this.serverParams.columnFilters.shipwith_ref}&order_no=${this.serverParams.columnFilters.order_no}&driver_name=${this.serverParams.columnFilters.driver_name}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&pick_plan=${this.serverParams.columnFilters.pick_plan}&pick_name=${this.serverParams.columnFilters.pick_name}&dest_plan=${this.serverParams.columnFilters.dest_plan}&dest_name=${this.serverParams.columnFilters.dest_name}&cannot_load_remarks=${this.serverParams.columnFilters.cannot_load_remarks}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },
    
    detailData(index, row) {
      var params = this.$onRandom(row.m_attendance_id);
      localStorage.setItem("detailDatas",JSON.stringify(row));
      // this.$router.push('/request-muat/detail').then(() => { this.$route.params.forms = row });
      window.location.href = "/request-leave/detail/" + params;
    },

    // load items is what brings back the rows from server
    loadItems() {
      let start = moment(String(this.dateFilter.startDate,)).format('YYYY-MM-DD');
      let end = moment(String(this.dateFilter.endDate,)).format('YYYY-MM-DD');
      const baseURI = this.$settings.endPointLincMobile + "mobile/m-attendance";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI + 
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&start=${start}&end=${end}&attendance_type=${this.serverParams.columnFilters.attendance_type}&leave_decission=${this.serverParams.columnFilters.leave_decission}&driver_name=${this.serverParams.columnFilters.driver_name}&driver_nmk=${this.serverParams.columnFilters.driver_nmk}&vehicle_no=${this.serverParams.columnFilters.vehicle_no}&reason=${this.serverParams.columnFilters.reason}&pool_name=${this.serverParams.columnFilters.pool_name}&location=${this.serverParams.columnFilters.location}`
        )
        .then((response) => {
          this.loading();
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    //ini function button
    filterSearch() {
      const periodCodeFilterElement = document.querySelector(
        '[name="vgt-period_code"]'
      ); //ini setiap field pasti ada vgt-

      const periodNameFilterElement = document.querySelector(
        '[name="vgt-period_name"]'
      ); //ini setiap field pasti ada vgt-

      var params = {
        columnFilters: {
          period_code: periodCodeFilterElement.value,
          period_name: periodNameFilterElement.value,
          price: "",
          price_gap: "",
          effective_date: "",
          expired_date: "",
        },
        page: 1,
      }; // params yang di kirim

      this.updateParams(params); //ini untuk update params vue good table
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MobileShipmentController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });
      if (!rolePermision.method_read) {
            this.$router.push("/authorized-error");
        }
      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
