<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="PostalCodeDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail  Master Penyebrangan
                  </h6>

                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Nama</label
                        >
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="$t('postalCodeTxt')"
                          v-model="forms.nama"
                          disabled
                        />
                        <div v-if="errors.nama">
                          <div
                            v-for="error in errors.nama"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Type Truck</label
                        >
                      </div>
                      <div class="col-lg-4">
                        <input
                          type="text"
                          class="form-control"
                          id="type_truck"
                          placeholder="type_truck"
                          v-model="forms.type_truck
"
                          disabled
                        />
                        <div v-if="errors.type_truck">
                          <div
                            v-for="error in errors.type_truck"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                     
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Biaya</label
                        >
                      </div>
                      <div class="col-lg-4">
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="biaya"
                          placeholder="biaya"
                          v-model="forms.biaya"
                          disabled
                        /> -->
                        <CurrencyInput
                          v-model.number="forms.biaya"
                          :options="{ currency: 'IDR' }"
                          class="form-control"
                          placeholder="Biaya Kapal (RP)"
                          disabled
                        />
                        <div v-if="errors.biaya">
                          <div
                            v-for="error in errors.biaya"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      
                    </div>

                  

                 

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a
                        v-if="permision_role.method_delete"
                        class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        "
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>


                      <span
                      
                        class="btn btn-success float-end "
                        style="margin-right: 5px"
                       
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator" style="font-size:16px"></i>
                        Calculate
                      </span>

                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import CurrencyInput from "@/components/_partials/inputCurrency";

export default {
  name: "PostalCodeDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    CurrencyInput

  },
  data() {
    return {
      rekon:false,
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    resetForm() {},

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/master-penyebrangan/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPoint + "rm-ferry-master/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/master-penyebrangan";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company?limit=100";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadData(id) {
   
      const baseURI = this.$settings.endPoint + "rm-ferry-master/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    loadCompanyNotLinc() {
      const baseURI =
        this.$settings.endPoint + `company?code=${this.companyCode}`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompanyNotLinc = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFerryMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    testReactivity(){
      this.rekon=!this.rekon;
      console.log(this.rekon);
    },


    calculate() {

      if ([this.forms.old_biaya].some(value => value === 0 || value === null || typeof value !== 'number')) {
        this.error('TIDAK BISA DILAKUKAN KALKULASI , MOHON UNTUK EDIT DAN SAVE SETELAH IKUT LAKUKAN KALKULASI LAGI'); 
        return;
      }

      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi Master Penyebrangan ini ?",
        text: "",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        html: `<p>Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan biaya penyebrangan ini</p><br>
                        <input type="checkbox" id="kalkulasiRekon" >
                        <label for="kalkulasiRekon">Kalkulasi Rekon</label>
                    `,
      }).then((result) => {
       
        const checkbox = document.getElementById('kalkulasiRekon');
        this.rekon = checkbox.checked;
                

        if (result.isConfirmed) {

         

          this.fade(true);
          
          this.forms.rm_ferry_master_id;
        

          // var encode = this.$onRandom(this.forms.rm_ferry_master_id);
          
        const baseURI = this.$settings.endPoint +"rm-ferry-master/calculation/"+this.forms.rm_ferry_master_id;
        this.$http
          .post(baseURI,{
                "rekon" : this.rekon==true ? 'yes': 'no', // yes or no
                // "link_page_pay_rate_archiving_detail_id" : window.location.origin+"/payable-rate/archiving/detail/"+encode
          },{ timeout: 3600000 })
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              //this.resetForm();
              this.errors = [];
              this.displayResponseTable(response.data.datas.messages);
              var payload=response.data.datas.messages;
              var encode = this.$onRandom(payload.rm_payable_calculation_id);
              payload.link_page_pay_rate_archiving_detail_id=window.location.origin+"/payable-rate/archiving/detail/"+encode;
              this.sendMail(payload);
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
        }

     
        
      });
    },



    displayResponseTable(messages) {
      const allowedKeys = [
        "rm_ferry_master_id",
        "Update RmFerryRute ",
        "Update RmUjoMaster ",
        "Update RmPayableAccessorial ",
        "Create RmPayableRateDetailLog ",
        "Create RmPayableAccessorialLog ",
      ];

      const filteredEntries = Object.entries(messages)
        .filter(([key]) => allowedKeys.includes(key))
        .map(([key, value]) => `<tr><td>${key}</td><td>${value}</td></tr>`);

      const tableContent = `
        <table class="table ">
          <thead>
            <tr>
              <th>Pesan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            ${filteredEntries.join('')}
          </tbody>
        </table>
      `;

      this.$swal({
        title: "Notification",
        html: tableContent,
        showCloseButton: true,
        showConfirmButton: false,
      });
  },





    sendMail(payload) {
      const baseURI = this.$settings.endPoint + "rm-ferry-master/calculation-mass-send-email";
          this.$http
            .post(baseURI, {
              messages:[payload]
            })
            .then((response) => {
              console.log(response)
              // this.success(response.data);
              var params = this.$onBehind(this.$route.params.id);
              this.loadData(params)

             
            })
            .catch((error) => {
              //this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
    },



   



    backForm() {
      window.location.href = "/master-penyebrangan";
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
</style>