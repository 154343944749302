import { render, staticRenderFns } from "./modalApproveWithReason.vue?vue&type=template&id=27ff6a1b&scoped=true"
import script from "./modalApproveWithReason.vue?vue&type=script&lang=js"
export * from "./modalApproveWithReason.vue?vue&type=script&lang=js"
import style0 from "./modalApproveWithReason.vue?vue&type=style&index=0&id=27ff6a1b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ff6a1b",
  null
  
)

export default component.exports